import { Globals } from './globals'
import { JL } from 'jsnlog'
import Vue from 'vue'
import { createGuid } from './helper'

JL.setOptions({
  defaultAjaxUrl: process.env.BASE_CLIENT_LOGGING_API_URL.replace(/\/*$/g, '') + '/clientlogger',
  requestId: Globals.UserRequestCorrelationId
})

const appender = JL.createAjaxAppender(createGuid())
appender.setOptions({
  batchSize: 5,
  batchTimeout: 5 * 1000,
  bufferSize: 20,
  storeInBufferLevel: 2000,
  level: 3000,
  sendWithBufferLevel: 5000
})

export class Logger {
  public static readonly ClientLogger = JL('ClientLog').setOptions({
    appenders: [appender]
  })
}

Vue.config.errorHandler = (err): void => {
  Logger.ClientLogger.fatalException(
    {
      msg: 'Uncaught Exception',
      url: document.location.href
    },
    err
  )
  throw err
}

if (window) {
  window.onerror = function(errorMsg, url, lineNumber, column, errorObj): boolean {
    Logger.ClientLogger.fatalException(
      {
        msg: 'Uncaught Exception',
        errorMsg: errorMsg,
        url: url,
        'line number': lineNumber,
        column: column
      },
      errorObj
    )
    return false
  }
}

if (typeof window !== 'undefined') {
  window.onunhandledrejection = function(event): boolean {
    Logger.ClientLogger.fatalException(
      {
        msg: 'unhandledrejection',
        url: document.location.href,
        errorMsg: event.reason ? event.reason.message : null
      },
      event.reason
    )
    return false
  }
}

import './user-menu.scss'

import { Component, Vue } from 'vue-property-decorator'

import { AuthHelper } from '../../common/auth-helper'
import Avatar from 'vue-avatar'
import { EmployeeApi } from '../../metadata/hrnext-performance-service-api/EmployeeApi'
import { EmployeeLightDto } from '../../metadata/hrnext-performance-service-api/models'
import { Globals } from '../../common/globals'
import { createApi } from '../../common/api-factory'

Vue.component('vue-avatar', Avatar)

@Component({
  template: require('./user-menu.pug'),
  props: ['userInfo', 'canAccessAdmin']
})
export class UserMenuComponent extends Vue {
  userInfo
  canAccessAdmin
  showAdminPortal
  avatarVisible = false
  userFullName
  employeeInfo: EmployeeLightDto = {}
  profilePhotoUrl = ''

  constructor() {
    super()
  }

  async created(): Promise<void> {
    this.showAdminPortal = this.$root.$data.showAdminPortal
    if (this.$root.$data.userInfo.FullName) {
      this.userFullName = this.$root.$data.userInfo.FullName
      this.avatarVisible = true
    }

    this.$watch('userInfo', () => {
      if (this.userInfo) {
        this.userFullName = this.userInfo.FullName
        this.avatarVisible = true
      }
    })

    // setTimeout(() => {
    //   if (this.userInfo) {
    //     this.avatarVisible = true
    //   } else {
    //     this.userInfo = Vue.prototype.$userInfo
    //     this.avatarVisible = true
    //   }
    // }, 1000)
  }

  async updated(): Promise<void> {
    await this.handleEmployeeInfo()
  }

  async handleEmployeeInfo(): Promise<void> {
    const employeeApi = createApi(EmployeeApi)
    this.employeeInfo = await employeeApi.getEmployeeBaseInfo(this.userInfo.CompanyEmployeeId)
    if (this.employeeInfo.imageFileName) {
      this.profilePhotoUrl = Globals.getDocumentShowByFileNameUrl(this.employeeInfo.imageFileName)
    }
  }

  logout(): void {
    AuthHelper.removeLocalSessionItemsOfUser()
    AuthHelper.userManager.signoutRedirect()
  }

  showProfilePhotoUploadingModal(): void {
    this.$root.$data.showUploadProfilePhotoModal = !this.$root.$data.showUploadProfilePhotoModal
  }

  toggleAdmin(): void {
    this.$root.$data.showAdminPortal = !this.$root.$data.showAdminPortal
    this.showAdminPortal = this.$root.$data.showAdminPortal

    if (!this.showAdminPortal) {
      this.$router.push('../dashboard')
    } else {
      this.$router.push('../admin-dashboard')
    }
  }
}

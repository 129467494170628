/**
 * HRNext Performance WebApi
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { LinqService, QueryOptions } from 'linquest';
import {
  PerformanceFormTargetRecommendationDto,
  TargetRecommendationEmployeeViewModel,
  TargetRecommendationViewModel,
  YearParameter,
} from './models';

import { IAjaxProvider } from 'jinqu';

/**
 * PerformanceFormTargetRecommendationApi - API class
 */
export class PerformanceFormTargetRecommendationApi extends LinqService {

  /**
   * Creates a new PerformanceFormTargetRecommendationApi class.
   *
   * @param ajaxProvider The Linquest Ajax Provider to inject. When not specified, default Fetch provider will be used.
   */
  constructor(ajaxProvider?: IAjaxProvider) {
    super("/performance-api", ajaxProvider);
  }

  /**
   * @param dto 
   */
  addOrUpdateAsync(dto?: PerformanceFormTargetRecommendationDto, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormTargetRecommendation/AddOrUpdateAsync`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = dto;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param recommendations 
   */
  addOrUpdateBulk(recommendations?: Array<TargetRecommendationViewModel>, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormTargetRecommendation/AddOrUpdateBulk`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = recommendations;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param id 
   */
  deleteAsync(id?: string, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormTargetRecommendation/DeleteAsync`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param id 
   */
  getAsync(id?: string, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormTargetRecommendation/GetAsync`;
    let query = this.createQuery<PerformanceFormTargetRecommendationDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param yearParams 
   */
  getCurrentFormTargetRecommendationDisplay(yearParams?: YearParameter, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormTargetRecommendation/GetCurrentFormTargetRecommendationDisplay`;
    let query = this.createQuery<TargetRecommendationViewModel>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = yearParams;
    query = query.withOptions(options);

    return query;
  }

  /**
   * @param id 
   */
  getTranslatedAsync(id?: string, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormTargetRecommendation/GetTranslatedAsync`;
    let query = this.createQuery<PerformanceFormTargetRecommendationDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   */
  list(options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormTargetRecommendation/List`;
    let query = this.createQuery<PerformanceFormTargetRecommendationDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    query = query.withOptions(options);

    return query;
  }

  /**
   * @param pageNumber 
   * @param pageSize 
   */
  listEmployeesForTargetRecommendation(pageNumber?: number, pageSize?: number, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormTargetRecommendation/ListEmployeesForTargetRecommendation`;
    let query = this.createQuery<TargetRecommendationEmployeeViewModel>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'pageNumber', value: pageNumber && pageNumber.toString() });
    // set query parameters
    options.params.push({ key: 'pageSize', value: pageSize && pageSize.toString() });
    query = query.withOptions(options);

    return query;
  }

  /**
   */
  listTeammatesOfCurrentUser(options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormTargetRecommendation/ListTeammatesOfCurrentUser`;
    let query = this.createQuery<TargetRecommendationEmployeeViewModel>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    query = query.withOptions(options);

    return query;
  }

  /**
   */
  listTranslated(options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormTargetRecommendation/ListTranslated`;
    let query = this.createQuery<PerformanceFormTargetRecommendationDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    query = query.withOptions(options);

    return query;
  }

  /**
   * @param dto 
   */
  saveAsync(dto?: PerformanceFormTargetRecommendationDto, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormTargetRecommendation/SaveAsync`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = dto;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param fullName 
   */
  searchEmployeesForTargetRecommendation(fullName?: string, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormTargetRecommendation/SearchEmployeesForTargetRecommendation`;
    let query = this.createQuery<TargetRecommendationEmployeeViewModel>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'fullName', value: fullName && fullName.toString() });
    query = query.withOptions(options);

    return query;
  }
}

/**
 * L is like '06.12.2014', LL is like '6 Aralık 2014'
 * To see more: https://devhints.io/moment
 */
export const momentDefaultDateFormat = 'L'

/**
 * L LT is like '06.12.2014 12:30', LLL is like '6 Aralık 2014 12:30'
 * To see more: https://devhints.io/moment
 */
export const momentDefaultDateTimeFormat = 'L LT'

/** Toaster sucess mesajları için default timeout (milisaniye) */
export const toastSuccessDurationInMs = 5000

/** Toaster mesajları için default timeout (milisaniye) */
export const toastDurationInMs = 8000

/** Not bileşenine yüklenen dosyalar için maksimum boyut (byte) */
export const maxFileSize = 20971520

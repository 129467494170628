import { Globals } from './globals'

export const emptyGuid: () => string = () => {
  return '00000000-0000-0000-0000-000000000000'
}

export const createGuid: () => string = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export const isNullOrEmpty: (value: string) => boolean = (value: string) => {
  const val = value.trim()
  if (!val || val === null || val === 'undefined') return true

  return false
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isNullOrUndefined: (param: any) => boolean = (param: any) => {
  if (param == null || typeof param === 'undefined') return true
  else return false
}

export const toLocalizedLowerCase: (text: string) => string = (text: string) => {
  let str = text

  if (Globals.i18n.locale == Globals.turkishLocaleCode) {
    const letters = { İ: 'i', I: 'ı', Ş: 'ş', Ğ: 'ğ', Ü: 'ü', Ö: 'ö', Ç: 'ç' }
    str = str.replace(/(([İIŞĞÜÇÖ]))/g, function(letter) {
      return letters[letter]
    })
  }

  return str.toLowerCase()
}

export const contains: (text: string, key) => boolean = (text: string, key) => {
  if (text == null || typeof text === 'undefined') return false
  if (key == null || typeof key === 'undefined') return false
  return !isNullOrUndefined(text) && toLocalizedLowerCase(text).includes(toLocalizedLowerCase(key))
}

export const calculateUpdatedFields: (originalViewModel, revisionedViewModel) => string[] = (
  originalViewModel,
  revisionedViewModel
) => {
  const original = originalViewModel
  const revisioned = revisionedViewModel
  const originalKeys = Object.keys(original)
  const revisionKeys = Object.keys(revisioned)
  const difference = originalKeys.filter(k => JSON.stringify(original[k]) !== JSON.stringify(revisioned[k]))
  const newProps = revisionKeys.filter(k => !originalKeys.asQueryable().any(x => x == k))

  // const ignoreDiffProperties = [
  //   'performanceForm',
  //   'childPerformanceFormDetails',
  //   'performanceFormDetailKeyResults',
  //   'performanceFormTargetCorrelations',
  //   'performanceFormDetailCompanyPriorities',
  //   'performanceFormDetailCompetenceEvaluations',
  //   'createdDateUTC',
  //   'createdUserId',
  //   'modifiedDateUTC',
  //   'modifiedUserId',
  //   'displayOrder',
  //   'performanceFormDetailResult',
  //   'performanceFormDetailStatus'
  // ]

  const allowedDiffProperties = [
    'targetName',
    'targetDescription',
    // 'formDetailType',
    // 'childFormDetailType',
    // 'formDetailStatus',
    'isActive',
    'targetStartDate',
    'targetEndDate',
    'isTargetVisibleToEveryone',
    'weight',
    'minimumWeight',
    'maximumWeight',
    'scoringType',
    'targetAssignmentType',
    'targetUnit',
    'targetValueMinimumDate',
    'targetValueDate',
    'targetValueMaximumDate',
    'targetValueMinimumPercent',
    'targetValuePercent',
    'targetValueMaximumPercent',
    'targetValueMinimumNumber',
    'targetValueNumber',
    'targetValueMaximumNumber',
    'targetValueMinimumText',
    'targetValueText',
    'targetValueMaximumText',
    'competenceAssignmentType'
    // 'templateDetailUnitPermissionType',
    // 'templateDetailWeightPermissionType',
    // 'templateDetailMinimumMaximumScorePermissionType'
  ]

  difference.push(...newProps)
  const diffArr = difference
    .asQueryable()
    .where(x => allowedDiffProperties.indexOf(x) >= 0)
    .toArray()
  return diffArr
}

export const handleNotifyClose: (toastDurationInMs) => void = toastDurationInMs => {
  const notifyElems = document.querySelectorAll('.notiflix-notify')
  for (let i = 0; i < notifyElems.length; i++) {
    const closeButtonElm = notifyElems[i].querySelectorAll('span.nx-close-button')[0]
    if (closeButtonElm)
      setTimeout(() => {
        if (closeButtonElm) closeButtonElm['click']()
      }, toastDurationInMs)
  }
}

/*
 * Start
 * General utils for managing cookies in Typescript.
 */
export function setCookie(name: string, val: string, expireDays: number): void {
  const date = new Date()
  const value = val

  date.setTime(date.getTime() + expireDays * 24 * 60 * 60 * 1000)

  document.cookie = name + '=' + value + '; expires=' + date.toUTCString() + '; path=/'
}

export function getCookie(name: string): string {
  const value = '; ' + document.cookie
  const parts = value.split('; ' + name + '=')

  if (parts.length == 2) {
    return parts
      .pop()
      .split(';')
      .shift()
  } else {
    return null
  }
}

export function deleteCookie(name: string): void {
  const date = new Date()

  // Set it expire in -1 days
  date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000)

  // Set it
  document.cookie = name + '=; expires=' + date.toUTCString() + '; path=/'
}

/*
 * End
 * General utils for managing cookies in Typescript.
 */

// Start Localization Helpers
export function setUserCultureOnCookie(cultureCode): void {
  setCookie('.AspNetCore.Culture', `c=${cultureCode}|uic=${cultureCode}`, 7)
}

// Cookie'de culture varsa döner, yoksa ve userInfoCultureCode değeri fonksiyona geçilmişse cookie'ye yazar.
export function getUserCultureFromCookie(userInfoCultureCode?): string {
  if (userInfoCultureCode != null) {
    setUserCultureOnCookie(userInfoCultureCode)
  }
  const userCookieCulture = getCookie('.AspNetCore.Culture')
  if (userCookieCulture != null) {
    const cultureArr = userCookieCulture.split('|')
    if (cultureArr.length > 0) {
      const cultureCodeArr = cultureArr[0].split('=')
      if (cultureCodeArr.length > 1) {
        return cultureCodeArr[1]
      }
    }
  }
  return userInfoCultureCode
}
// End Localization Helpers

/**
 * Combo'lardan seçilebilecek yulları getirir
 * Şimdiki mantık, 2019'dan başla ve mevcut yıl üzerine 3 ekle
 */
export function getSupportedYears(): Array<number> {
  const selectableYears = []
  for (let year = 2019; year <= new Date().getFullYear() + 3; year++) {
    selectableYears.push(year)
  }
  return selectableYears
}

export async function GetFeatureToggle(toggle, configApi): Promise<boolean> {
  const value = Globals.getFeatureToggleCache(toggle)
  if (value) {
    return value === true
  }
  const toggleVal = await configApi.isFeatureEnabled(toggle)
  Globals.setFeatureToggleCache(toggle, toggleVal)
  return toggleVal
}

export function getSupportedOldYears(): Array<number> {
  const selectableYears = []
  for (let year = 2013; year <= new Date().getFullYear() + 3; year++) {
    selectableYears.push(year)
  }
  return selectableYears
}

export function mapToFieldObject(title: any[], fields): object {
  const resultArray = Object.keys(fields).map(function(index) {
    const res = fields[index]
    return res
  })

  const obj = {}
  for (let i = 0; i < title.length; i++) {
    obj[title[i]] = resultArray[i]
  }
  return obj
}

export function isObjectEmpty(objectName): boolean {
  return objectName && Object.keys(objectName).length === 0 && objectName.constructor === Object
}

export default class VueMultiSelectEsData {
  static data = {
    selectLabel: 'Seleccionar',
    selectGroupLabel: 'Presione la tecla "Enter / Enter" para seleccionar el grupo',
    selectedLabel: 'Seleccionado',
    deselectLabel: 'Eliminar',
    deselectGroupLabel: 'Presione la tecla "Enter / Enter" para deseleccionar el grupo',
    limitText: 'y más _{count}_',
    noResult: 'No hubo resultados. Cambia tus criterios de búsqueda y vuelve a intentarlo.',
    noOptions: 'Lista vacía',
    maxElements: 'Puede seleccionar hasta _{max}_. Deseleccione al menos una selección para volver a seleccionarla'
  }
}

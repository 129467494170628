import './portal-sidebar-menu.scss'

import { Component, Vue } from 'vue-property-decorator'
import { ConfigApi, CurrentPerformanceFormSummaryViewModel } from '../../metadata/hrnext-performance-service-api'

import { BaseComponent } from '../../common/base-component'
import { GetFeatureToggle } from '../../common/helper'
import { Globals } from '../../common/globals'
import { PerformanceFormApi } from './../../metadata/hrnext-performance-service-api/PerformanceFormApi'
import { createApi } from '../../common'

@Component({
  template: require('./portal-sidebar-menu.pug'),
  props: ['isFmoduleEval360Allowed', 'isFmoduleOkrAllowed']
})
export class PortalSidebarMenuComponent extends BaseComponent {
  // Props
  isFmoduleEval360Allowed
  isFmoduleOkrAllowed
  //
  showEvaluation = false
  showComparisonAnalysis = false
  showEvaluation360 = false
  showOkr = false
  configApi: ConfigApi
  showEvaluationBullet = false
  performanceFormSummaryModel: CurrentPerformanceFormSummaryViewModel = {}
  performanceFormApi: PerformanceFormApi

  constructor() {
    super()
    this.configApi = createApi(ConfigApi)
    this.performanceFormApi = createApi(PerformanceFormApi)
  }

  async created(): Promise<void> {
    await super.created(true)
    this.showEvaluation = await this.isFeatureEnabled(Globals.EvaluationToggle)
    this.showComparisonAnalysis = await this.isFeatureEnabled(Globals.ComparisonAnalysis)
    //this.showEvaluation360 = false
    //  const isFeatureAllowed = await this.isFeatureEnabled(Globals.Evaluation360)
    this.showEvaluation360 = this.isFmoduleEval360Allowed

    this.$watch('isFmoduleEval360Allowed', () => {
      this.showEvaluation360 = this.isFmoduleEval360Allowed
    })

    this.showOkr = this.isFmoduleOkrAllowed
    this.$watch('isFmoduleOkrAllowed', () => {
      this.showOkr = this.isFmoduleOkrAllowed
    })

    this.getCurrentFormSummaryInfo()
  }

  isActive(urls: string[]): boolean {
    const urlPath = this.$route.path.toLowerCase()
    return urls.asQueryable().any(x => x.toLowerCase() == urlPath)
  }

  async isFeatureEnabled(toggle): Promise<boolean> {
    return await GetFeatureToggle(toggle, this.configApi)
  }

  async getCurrentFormSummaryInfo(): Promise<void> {
    this.performanceFormSummaryModel = await this.performanceFormApi.getCurrentPerformanceFormSummary(
      this.activeYearParams
    )
    this.showEvaluationBullet = parseInt(this.performanceFormSummaryModel?.activePeriodType) === 3
  }
}

/**
 * HRNext Performance WebApi
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { IAjaxProvider } from 'jinqu';
import { LinqService, QueryOptions } from 'linquest';
import {
  ThreeSixtyWaitingApprovalsViewModel,
  RejectThreeSixtyEvaluatorsRequest,
  ThreeSixtyEvaluatorRequest,
  GetThreeSixtyEvaluatorsForEditRequest,
  GetThreeSixtyWaitingApprovalsRequest,
  SaveThreeSixtyEvaluatorsAsApprovedRequest,
  ThreeSixtyEvaluatorDto,
  ApproveThreeSixtyEvaluatorsRequest,
  SaveThreeSixtyEvaluatorsRequest,
  SendThreeSixtyEvaluationReminderRequest,
  RollBackThreeSixtyFormRequest,
  ThreeSixtyEvaluatingEmployeesViewModel,
  GetThreeSixtyEvaluatorsRequest,
  SendToApprovalThreeSixtyEvaluatorsRequest,
  GetThreeSixtyEvaluatingEmployeesRequest,
  ThreeSixtyEvaluatorsViewModel,
} from './models';

/**
 * ThreeSixtyEvaluatorApi - API class
 */
export class ThreeSixtyEvaluatorApi extends LinqService {

  /**
   * Creates a new ThreeSixtyEvaluatorApi class.
   *
   * @param ajaxProvider The Linquest Ajax Provider to inject. When not specified, default Fetch provider will be used.
   */
  constructor(ajaxProvider?: IAjaxProvider) {
    super("/performance-api", ajaxProvider);
  }

  /**
   * @param dto 
   */
  addOrUpdateAsync(dto?: ThreeSixtyEvaluatorDto, options?: QueryOptions) {
    // create query
    const url = `/ThreeSixtyEvaluator/AddOrUpdateAsync`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = dto;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param request 
   */
  approveThreeSixtyEvaluators(request?: ApproveThreeSixtyEvaluatorsRequest, options?: QueryOptions) {
    // create query
    const url = `/ThreeSixtyEvaluator/ApproveThreeSixtyEvaluators`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = request;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param id 
   */
  deleteAsync(id?: string, options?: QueryOptions) {
    // create query
    const url = `/ThreeSixtyEvaluator/DeleteAsync`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param id 
   */
  getAsync(id?: string, options?: QueryOptions) {
    // create query
    const url = `/ThreeSixtyEvaluator/GetAsync`;
    let query = this.createQuery<ThreeSixtyEvaluatorDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param request 
   */
  getThreeSixtyEvaluatingEmployees(request?: GetThreeSixtyEvaluatingEmployeesRequest, options?: QueryOptions) {
    // create query
    const url = `/ThreeSixtyEvaluator/GetThreeSixtyEvaluatingEmployees`;
    let query = this.createQuery<ThreeSixtyEvaluatingEmployeesViewModel>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = request;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param request 
   */
  getThreeSixtyEvaluators(request?: GetThreeSixtyEvaluatorsRequest, options?: QueryOptions) {
    // create query
    const url = `/ThreeSixtyEvaluator/GetThreeSixtyEvaluators`;
    let query = this.createQuery<ThreeSixtyEvaluatorsViewModel>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = request;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param request 
   */
  getThreeSixtyEvaluatorsForEdit(request?: GetThreeSixtyEvaluatorsForEditRequest, options?: QueryOptions) {
    // create query
    const url = `/ThreeSixtyEvaluator/GetThreeSixtyEvaluatorsForEdit`;
    let query = this.createQuery<ThreeSixtyEvaluatorsViewModel>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = request;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param request 
   */
  getThreeSixtyWaitingApprovals(request?: GetThreeSixtyWaitingApprovalsRequest, options?: QueryOptions) {
    // create query
    const url = `/ThreeSixtyEvaluator/GetThreeSixtyWaitingApprovals`;
    let query = this.createQuery<ThreeSixtyWaitingApprovalsViewModel>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = request;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param id 
   */
  getTranslatedAsync(id?: string, options?: QueryOptions) {
    // create query
    const url = `/ThreeSixtyEvaluator/GetTranslatedAsync`;
    let query = this.createQuery<ThreeSixtyEvaluatorDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   */
  list(options?: QueryOptions) {
    // create query
    const url = `/ThreeSixtyEvaluator/List`;
    let query = this.createQuery<ThreeSixtyEvaluatorDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    query = query.withOptions(options);

    return query;
  }

  /**
   */
  listTranslated(options?: QueryOptions) {
    // create query
    const url = `/ThreeSixtyEvaluator/ListTranslated`;
    let query = this.createQuery<ThreeSixtyEvaluatorDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    query = query.withOptions(options);

    return query;
  }

  /**
   * @param request 
   */
  rejectThreeSixtyEvaluators(request?: RejectThreeSixtyEvaluatorsRequest, options?: QueryOptions) {
    // create query
    const url = `/ThreeSixtyEvaluator/RejectThreeSixtyEvaluators`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = request;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param request 
   */
  rollBackThreeSixtyForm(request?: RollBackThreeSixtyFormRequest, options?: QueryOptions) {
    // create query
    const url = `/ThreeSixtyEvaluator/RollBackThreeSixtyForm`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = request;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param dto 
   */
  saveAsync(dto?: ThreeSixtyEvaluatorDto, options?: QueryOptions) {
    // create query
    const url = `/ThreeSixtyEvaluator/SaveAsync`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = dto;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param request 
   */
  saveThreeSixtyEvaluators(request?: SaveThreeSixtyEvaluatorsRequest, options?: QueryOptions) {
    // create query
    const url = `/ThreeSixtyEvaluator/SaveThreeSixtyEvaluators`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = request;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param request 
   */
  saveThreeSixtyEvaluatorsAsApproved(request?: SaveThreeSixtyEvaluatorsAsApprovedRequest, options?: QueryOptions) {
    // create query
    const url = `/ThreeSixtyEvaluator/SaveThreeSixtyEvaluatorsAsApproved`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = request;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param input 
   */
  sendThreeSixtyEvaluationReminder(input?: SendThreeSixtyEvaluationReminderRequest, options?: QueryOptions) {
    // create query
    const url = `/ThreeSixtyEvaluator/SendThreeSixtyEvaluationReminder`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = input;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param request 
   */
  sendToApprovalThreeSixtyEvaluators(request?: SendToApprovalThreeSixtyEvaluatorsRequest, options?: QueryOptions) {
    // create query
    const url = `/ThreeSixtyEvaluator/SendToApprovalThreeSixtyEvaluators`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = request;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param request 
   */
  updateBulkThreeSixtyEvaluatorAsync(request?: ThreeSixtyEvaluatorRequest, options?: QueryOptions) {
    // create query
    const url = `/ThreeSixtyEvaluator/UpdateBulkThreeSixtyEvaluatorAsync`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = request;
    query = query.withOptions(options);

    return query.firstAsync();
  }
}

export default class VueMultiSelectFrData {
  static data = {
    selectLabel: 'Sélectionner',
    selectGroupLabel: 'Appuyez sur la touche "Entrée / Enter" pour sélectionner le groupe',
    selectedLabel: 'Sélectionné',
    deselectLabel: 'Supprimer',
    deselectGroupLabel: 'Appuyez sur la touche "Entrée / Enter" pour désélectionner le groupe',
    limitText: 'et plus _{count}_',
    noResult: 'Aucun résultat. Modifiez vos critères de recherche et réessayez',
    noOptions: 'Liste vide',
    maxElements:
      "Vous pouvez sélectionner jusqu'à _{max}_. Désélectionnez au moins une sélection pour en resélectionner une"
  }
}

/**
 * HRNext Performance WebApi
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import {
  CommonFormRequestDto,
  PerformanceFormEvaluationAnswerForEmployeeBulkRequestDto,
  PerformanceFormEvaluationAnswerForEmployeeDto,
} from './models';
import { LinqService, QueryOptions } from 'linquest';

import { IAjaxProvider } from 'jinqu';

/**
 * PerformanceFormEvaluationAnswerForEmployeeApi - API class
 */
export class PerformanceFormEvaluationAnswerForEmployeeApi extends LinqService {

  /**
   * Creates a new PerformanceFormEvaluationAnswerForEmployeeApi class.
   *
   * @param ajaxProvider The Linquest Ajax Provider to inject. When not specified, default Fetch provider will be used.
   */
  constructor(ajaxProvider?: IAjaxProvider) {
    super("/performance-api", ajaxProvider);
  }

  /**
   * @param dto 
   */
  addOrUpdateAsync(dto?: PerformanceFormEvaluationAnswerForEmployeeDto, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormEvaluationAnswerForEmployee/AddOrUpdateAsync`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = dto;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param request 
   */
  addOrUpdateBulk(request?: PerformanceFormEvaluationAnswerForEmployeeBulkRequestDto, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormEvaluationAnswerForEmployee/AddOrUpdateBulk`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = request;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param request 
   */
  checkEvaluationSurveyForEmployee(request?: CommonFormRequestDto, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormEvaluationAnswerForEmployee/CheckEvaluationSurveyForEmployee`;
    let query = this.createQuery<boolean>(url);
    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = request;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param id 
   */
  deleteAsync(id?: string, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormEvaluationAnswerForEmployee/DeleteAsync`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param request 
   */
  deleteBulk(request?: CommonFormRequestDto, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormEvaluationAnswerForEmployee/DeleteBulk`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.data = request;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param id 
   */
  getAsync(id?: string, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormEvaluationAnswerForEmployee/GetAsync`;
    let query = this.createQuery<PerformanceFormEvaluationAnswerForEmployeeDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param id 
   */
  getTranslatedAsync(id?: string, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormEvaluationAnswerForEmployee/GetTranslatedAsync`;
    let query = this.createQuery<PerformanceFormEvaluationAnswerForEmployeeDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }


  /**
 * @param request 
 */
  isEmployeeCompletedEvaluationSurvey(request?: CommonFormRequestDto, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormEvaluationAnswerForEmployee/IsEmployeeCompletedEvaluationSurvey`;
    let query = this.createQuery<number>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = request;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   */
  list(options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormEvaluationAnswerForEmployee/List`;
    let query = this.createQuery<PerformanceFormEvaluationAnswerForEmployeeDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    query = query.withOptions(options);

    return query;
  }

  /**
   * @param formId 
   */
  listByFormId(formId?: string, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormEvaluationAnswerForEmployee/ListByFormId`;
    let query = this.createQuery<PerformanceFormEvaluationAnswerForEmployeeDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'formId', value: formId && formId.toString() });
    query = query.withOptions(options);

    return query;
  }

  /**
   */
  listTranslated(options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormEvaluationAnswerForEmployee/ListTranslated`;
    let query = this.createQuery<PerformanceFormEvaluationAnswerForEmployeeDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    query = query.withOptions(options);

    return query;
  }

  /**
   * @param dto 
   */
  saveAsync(dto?: PerformanceFormEvaluationAnswerForEmployeeDto, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormEvaluationAnswerForEmployee/SaveAsync`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = dto;
    query = query.withOptions(options);

    return query.firstAsync();
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { IAjaxProvider } from 'jinqu'
import axios from 'axios'

/** API'den gelen response'lar içerisindeki ISO Date formatlı alanları
 * TypeScript nesnelerine map ederken, tarihe çevirip map edebilmek için kullanıyoruz.
 * Normalde TS'de objenin tipi Date bile olsa, backing field'inde string tutacak şekilde davranıyordu.
 */
function isoDateFormatResolver(key, value): any {
  const reIso = /^(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d)$/

  if (typeof value === 'string') {
    const isIsoDateFormatMatch = reIso.exec(value)
    if (isIsoDateFormatMatch) {
      const val = new Date(value)
      return val
    }
  }
  return value
}

function combinedHrnextRequestReplacer(key, value): any {
  if (this[key] instanceof Date) {
    // INFO: Date Format Fix
    // API'ye yapılacak requestlerdeki timezone olayını browser bazlı yapmaktan kurtarmak için
    // bu yöntem kullanıldı. Bu sayede datepicker'dan seçilen tarihin, UTC-3 yüzünden
    // bir gün önceye set edilmesi olayı çözüldü
    const dateObject = new Date(this[key])
    const hoursDiff = dateObject.getHours() - dateObject.getTimezoneOffset() / 60
    dateObject.setHours(hoursDiff)
    return dateObject.toISOString()
  } else if (key === 'translations' && value instanceof Array) {
    // INFO: Unnecessary Translation Object Fix
    // multilingualinput componentimiz, olmayan dillerin verilerini gösterebilmek için
    // Çoklu dil destekleyen Dto'ları translations array'ine olmayan kayıtları ekliyor
    // Buradaki filtre ile "languageCountryCode" ve "displayOrder" dışında bir property barındırmayan nesneleri filtreliyoruz
    return value.filter(translation => Object.keys(translation).length > 2)
  }

  return value
}

export const axiosAjaxProvider: IAjaxProvider = {
  ajax<TResult>(o: any): PromiseLike<TResult> {
    if (Array.isArray(o.params)) {
      o.params = Object.assign({}, ...o.params.map(item => ({ [item['key']]: item['value'] })))
    }

    // TODO: Buradaki interceptor'ları uygun bir yere taşımak için Kemal ile konuş
    o.transformRequest = (data, headers): any => {
      if (data) {
        headers['Content-Type'] = 'application/json;charset=utf-8'
        return JSON.stringify(data, combinedHrnextRequestReplacer)
      }
    }

    // We are overriding the default JSON.parse with the ISO date supported parser
    o.transformResponse = (data): any => {
      if (data) {
        let json = data
        try {
          json = JSON.parse(data, isoDateFormatResolver)
          return json
        } catch {
          return json
        }
      } else {
        return data
      }
    }

    return axios.request<TResult>(o).then(r => ({ value: r.data, response: r })) as any
  }
}

import { LinqService } from 'linquest'
export function createApi<TApi extends LinqService>(ApiType: { new (provider: IAjaxProvider): TApi }): any {
  return new ApiType(axiosAjaxProvider)
}

import { Component, Vue } from 'vue-property-decorator'
import './toggle-btn.scss'

@Component({
  template: require('./toggle-btn.pug'),
  props: {
    dataLabelOn: {
      type: String,
      default: 'On'
    },
    dataLabelOff: {
      type: String,
      default: 'Off'
    },
    value: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: 'toggle-btn__input'
    }
  }
})
export class ToggleBtnComponent extends Vue {
  constructor() {
    super()
  }
}

import { BaseComponent } from '../../common/base-component'
import { LanguageDefinition } from '../../common/languageDefinition'
import { Component } from 'vue-property-decorator'
import './multilingual-input.scss'

@Component({
  template: require('./multilingual-input.pug'),
  props: {
    translations: { type: Array },
    prop: { type: String },
    maxlength: { type: Number },
    disabled: { type: Boolean }
  }
})
export class MultilingualInputComponent extends BaseComponent {
  someBigNumberForSortingLast = 999
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  translations: Array<any>
  availableLanguages: Map<string, LanguageDefinition>
  toggleCollapse = false
  unwatch: () => void

  async created(): Promise<void> {
    this.availableLanguages = LanguageDefinition.GetAllDefinedLanguages()

    if (this.translations) {
      // If already has translations on create
      // consolidate missing translations for the first time
      this.consolideTranslations()
    }

    this.registerWatcher()
  }

  registerWatcher(): void {
    this.unwatch = this.$watch('translations', function(newValue, oldValue): void {
      this.consolideTranslations()
    })
  }

  unRegisterWatcher(): void {
    if (this.unwatch) this.unwatch()
  }

  consolideTranslations(): void {
    if (this.translations == undefined) return

    this.unRegisterWatcher() // to prevent infinite loop

    this.availableLanguages.forEach((languageDefinition: LanguageDefinition, languageCountryCode: string) => {
      const existingTranslation = this.translations.find(t => t.languageCountryCode === languageCountryCode)
      if (existingTranslation == null) {
        this.translations.push({
          languageCountryCode: languageCountryCode,
          displayOrder: languageDefinition.displayOrder
        })
      } else {
        existingTranslation.displayOrder = languageDefinition.displayOrder
      }
    })

    if (this.translations) {
      this.translations.sort(
        (s1, s2) =>
          (s1.displayOrder ? s1.displayOrder : this.someBigNumberForSortingLast) -
          (s2.displayOrder ? s2.displayOrder : this.someBigNumberForSortingLast)
      )
    }

    // Bu nedense işe yaramadı, ancak bu hali de iş görüyor
    // this.registerWatch()
  }

  /**
   * Vue.js bir textbox'a bir şey yazılıp tekrar silindiğinde,
   * o nesnenin öyle v-model'de yazdığı gibi bir prop'u olduğunu kabul ediyor.
   * Bu ise combinedHrnextRequestReplacer methodu için istemediğimiz bir sonuç veriyor.
   * Bunları temizlemek için aşağıdaki methodu kullanıyoruz.
   */
  assumeEmptyValuesAsUndefined(translation, prop): void {
    if (translation[prop] === '') delete translation[prop]
  }

  getLanguageIconPath(languageCountryCode: string): string {
    if (!this.availableLanguages) return ''

    const languageDefinition = this.availableLanguages.get(languageCountryCode)
    return languageDefinition ? languageDefinition.languageIconPath : ''
  }
}

/**
 * HRNext Performance WebApi
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { IAjaxProvider } from 'jinqu';
import { LinqService, QueryOptions } from 'linquest';
import {
  SpreadsheetDto,
} from './models';

/**
 * SpreadsheetApi - API class
 */
export class SpreadsheetApi extends LinqService {

  /**
   * Creates a new SpreadsheetApi class.
   *
   * @param ajaxProvider The Linquest Ajax Provider to inject. When not specified, default Fetch provider will be used.
   */
  constructor(ajaxProvider?: IAjaxProvider) {
    super("/performance-api", ajaxProvider);
  }

  /**
   * @param dto 
   */
  addOrUpdateAsync(dto?: SpreadsheetDto, options?: QueryOptions) {
    // create query
    const url = `/Spreadsheet/AddOrUpdateAsync`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = dto;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param id 
   */
  deleteAsync(id?: string, options?: QueryOptions) {
    // create query
    const url = `/Spreadsheet/DeleteAsync`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param employeeId 
   * @param deviceId 
   */
  deleteByEmployeeIdAndDeviceId(employeeId?: string, deviceId?: string, options?: QueryOptions) {
    // create query
    const url = `/Spreadsheet/DeleteByEmployeeIdAndDeviceId`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'DELETE';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'employeeId', value: employeeId && employeeId.toString() });
    // set query parameters
    options.params.push({ key: 'deviceId', value: deviceId && deviceId.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param id 
   */
  getAsync(id?: string, options?: QueryOptions) {
    // create query
    const url = `/Spreadsheet/GetAsync`;
    let query = this.createQuery<SpreadsheetDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param id 
   */
  getTranslatedAsync(id?: string, options?: QueryOptions) {
    // create query
    const url = `/Spreadsheet/GetTranslatedAsync`;
    let query = this.createQuery<SpreadsheetDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   */
  list(options?: QueryOptions) {
    // create query
    const url = `/Spreadsheet/List`;
    let query = this.createQuery<SpreadsheetDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    query = query.withOptions(options);

    return query;
  }

  /**
   */
  listTranslated(options?: QueryOptions) {
    // create query
    const url = `/Spreadsheet/ListTranslated`;
    let query = this.createQuery<SpreadsheetDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    query = query.withOptions(options);

    return query;
  }

  /**
   * @param dto 
   */
  saveAsync(dto?: SpreadsheetDto, options?: QueryOptions) {
    // create query
    const url = `/Spreadsheet/SaveAsync`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = dto;
    query = query.withOptions(options);

    return query.firstAsync();
  }
}

/**
 * HRNext Performance WebApi
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { IAjaxProvider } from 'jinqu'
import { LinqService, QueryOptions } from 'linquest'
import {
  UserModuleEmployeeBulkRequestDto,
  UserModuleEmployeeListBulkRequestDto,
  UserModuleEmployeeQueryDto,
  UserModuleEmployeeDto
} from './models'

/**
 * UserModuleEmployeeApi - API class
 */
export class UserModuleEmployeeApi extends LinqService {
  /**
   * Creates a new UserModuleEmployeeApi class.
   *
   * @param ajaxProvider The Linquest Ajax Provider to inject. When not specified, default Fetch provider will be used.
   */
  constructor(ajaxProvider?: IAjaxProvider) {
    super("/performance-api", ajaxProvider)
  }

  /**
   * @param dto
   */
  addOrUpdateAsync(dto?: UserModuleEmployeeDto, options?: QueryOptions) {
    // create query
    const url = `/UserModuleEmployee/AddOrUpdateAsync`
    let query = this.createQuery<any>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'POST'
    options.params = options.params || []
    options.headers = options.headers || {}

    // set body parameter
    options.data = dto
    query = query.withOptions(options)

    return query.firstAsync()
  }

  /**
   * @param userModuleEmployeeBulkRequestDto
   */
  addOrUpdateBulk(userModuleEmployeeBulkRequestDto?: UserModuleEmployeeBulkRequestDto, options?: QueryOptions) {
    // create query
    const url = `/UserModuleEmployee/AddOrUpdateBulk`
    let query = this.createQuery<any>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'POST'
    options.params = options.params || []
    options.headers = options.headers || {}

    // set body parameter
    options.data = userModuleEmployeeBulkRequestDto
    query = query.withOptions(options)

    return query.firstAsync()
  }

  /**
   * @param id
   */
  deleteAsync(id?: string, options?: QueryOptions) {
    // create query
    const url = `/UserModuleEmployee/DeleteAsync`
    let query = this.createQuery<any>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'GET'
    options.params = options.params || []
    options.headers = options.headers || {}

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() })
    query = query.withOptions(options)

    return query.firstAsync()
  }

  /**
   * @param id
   */
  getAsync(id?: string, options?: QueryOptions) {
    // create query
    const url = `/UserModuleEmployee/GetAsync`
    let query = this.createQuery<UserModuleEmployeeDto>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'GET'
    options.params = options.params || []
    options.headers = options.headers || {}

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() })
    query = query.withOptions(options)

    return query.firstAsync()
  }

  /**
   * @param id
   */
  getTranslatedAsync(id?: string, options?: QueryOptions) {
    // create query
    const url = `/UserModuleEmployee/GetTranslatedAsync`
    let query = this.createQuery<UserModuleEmployeeDto>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'GET'
    options.params = options.params || []
    options.headers = options.headers || {}

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() })
    query = query.withOptions(options)

    return query.firstAsync()
  }

  /**
   * @param userModuleEmployeeListBulkRequestDto
   */
  getUserModuleEmployeeBulk(
    userModuleEmployeeListBulkRequestDto?: UserModuleEmployeeListBulkRequestDto,
    options?: QueryOptions
  ) {
    // create query
    const url = `/UserModuleEmployee/GetUserModuleEmployeeBulk`
    let query = this.createQuery<UserModuleEmployeeDto>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'POST'
    options.params = options.params || []
    options.headers = options.headers || {}

    // set body parameter
    options.data = userModuleEmployeeListBulkRequestDto
    query = query.withOptions(options)

    return query
  }

  /**
   * @param employeeId
   * @param featureModuleId
   */
  ifEmployeeAuthorizedToFeatureModule(employeeId?: string, featureModuleId?: string, options?: QueryOptions) {
    // create query
    const url = `/UserModuleEmployee/IfEmployeeAuthorizedToFeatureModule`
    let query = this.createQuery<boolean>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'GET'
    options.params = options.params || []
    options.headers = options.headers || {}

    // set query parameters
    options.params.push({ key: 'employeeId', value: employeeId && employeeId.toString() })
    // set query parameters
    options.params.push({ key: 'featureModuleId', value: featureModuleId && featureModuleId.toString() })
    query = query.withOptions(options)

    return query.firstAsync()
  }

  /**
   */
  list(options?: QueryOptions) {
    // create query
    const url = `/UserModuleEmployee/List`
    let query = this.createQuery<UserModuleEmployeeDto>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'GET'
    options.params = options.params || []
    options.headers = options.headers || {}

    query = query.withOptions(options)

    return query
  }

  /**
   */
  listTranslated(options?: QueryOptions) {
    // create query
    const url = `/UserModuleEmployee/ListTranslated`
    let query = this.createQuery<UserModuleEmployeeDto>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'GET'
    options.params = options.params || []
    options.headers = options.headers || {}

    query = query.withOptions(options)

    return query
  }

  /**
   * @param userModuleEmployeeBulkRequestDto
   */
  removeUserModuleEmployeesBulk(
    userModuleEmployeeBulkRequestDto?: UserModuleEmployeeBulkRequestDto,
    options?: QueryOptions
  ) {
    // create query
    const url = `/UserModuleEmployee/RemoveUserModuleEmployeesBulk`
    let query = this.createQuery<any>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'POST'
    options.params = options.params || []
    options.headers = options.headers || {}

    // set body parameter
    options.data = userModuleEmployeeBulkRequestDto
    query = query.withOptions(options)

    return query.firstAsync()
  }

  /**
   * @param dto
   */
  saveAsync(dto?: UserModuleEmployeeDto, options?: QueryOptions) {
    // create query
    const url = `/UserModuleEmployee/SaveAsync`
    let query = this.createQuery<any>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'POST'
    options.params = options.params || []
    options.headers = options.headers || {}

    // set body parameter
    options.data = dto
    query = query.withOptions(options)

    return query.firstAsync()
  }

  /**
   * @param employeeIds
   */
  searchAsync(employeeIds?: Array<string>, options?: QueryOptions) {
    // create query
    const url = `/UserModuleEmployee/SearchAsync`
    let query = this.createQuery<UserModuleEmployeeQueryDto>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'POST'
    options.params = options.params || []
    options.headers = options.headers || {}

    // set body parameter
    options.data = employeeIds
    query = query.withOptions(options)

    return query
  }
}

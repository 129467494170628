/* eslint-disable @typescript-eslint/no-explicit-any */

import { AuthHelper } from '../common/auth-helper'
import Axios from 'axios'
import { Globals } from '../common/globals'
import Notiflix from 'notiflix'
import VueRouter from 'vue-router'
import { handleNotifyClose } from '../common/helper'
import { toastDurationInMs } from '../common/constants'

function removeLastNElements(arr, n): any {
  if (n <= 0) {
    return arr
  }

  if (n >= arr.length) {
    return []
  }

  return arr.slice(0, arr.length - n)
}

export const handleErrorAxiosInterceptors = (router: VueRouter) => (error: any): Promise<void> => {
  const notiflixOptions = { timeout: toastDurationInMs, width: '500px', messageMaxLength: 500, closeButton: true }

  Notiflix.Notify.merge(notiflixOptions)

  if (!error.response) {
    Notiflix.Notify.failure(Globals.i18n.t('main.axiosInterceptors.unknownErrorOccured').toString())
    handleNotifyClose(toastDurationInMs)
  } else if (error.response.status === 401) {
    AuthHelper.userManager.signinRedirect()
  } else if (error.response.status === 400 && error.response.data.Error.ValidationErrors) {
    let validationMessage = ''
    let keys = Object.keys(error.response.data.Error.ValidationErrors)
    if (keys.length > 3) {
      keys = removeLastNElements(keys, keys.length - 4)
    }
    keys.forEach(element => {
      validationMessage += error.response.data.Error.ValidationErrors[element] + ' <br/>'
    })

    Notiflix.Notify.failure(validationMessage, { plainText: false })
    handleNotifyClose(toastDurationInMs)
  } else if (error.response.status === 400 && error.response.data.Error.BusinessErrorMessage) {
    Notiflix.Notify.failure(error.response.data.Error.BusinessErrorMessage)
    handleNotifyClose(toastDurationInMs)
  } else if (error.response.status === 400 || error.response.status === 422 || error.response.status === 500) {
    Notiflix.Notify.failure(
      error.response.data.Error?.ErrorMessage ?? Globals.i18n.t('main.axiosInterceptors.unknownErrorOccured')
    )
    handleNotifyClose(toastDurationInMs)
  } else if (error.response.status === 403) {
    router.push({ path: '/unauthorized' })
  }
  return Promise.reject(error.response)
}

export const handleAuthAxiosInterceptors = (router: VueRouter): void => {
  Axios.interceptors.response.use(response => response, handleErrorAxiosInterceptors(router))
}

export const handleVueAxiosInterceptors = (vueApp: any): void => {
  Axios.interceptors.request.use(
    function(config) {
      // Set user culture for every request
      config.params['culture'] = Globals.i18n.locale

      // Set Correlation Id for every request
      config.headers['X-Correlation-ID'] = Globals.UserRequestCorrelationId

      vueApp.isBusyCount++
      return config
    },
    function(error) {
      vueApp.isBusyCount++
      return Promise.reject(error)
    }
  )
  Axios.interceptors.response.use(
    function(response) {
      setTimeout(() => {
        vueApp.isBusyCount--
      }, 500)
      return response
    },
    function(error) {
      setTimeout(() => {
        vueApp.isBusyCount--
      }, 500)
      return Promise.reject(error)
    }
  )
}

/**
 * HRNext Performance WebApi
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { IAjaxProvider } from 'jinqu';
import { LinqService, QueryOptions } from 'linquest';
import {
  ScheduledJobExecutionRequest,
  ScheduledJobManagerViewModel,
} from './models';

/**
 * ScheduledJobApi - API class
 */
export class ScheduledJobApi extends LinqService {

  /**
   * Creates a new ScheduledJobApi class.
   *
   * @param ajaxProvider The Linquest Ajax Provider to inject. When not specified, default Fetch provider will be used.
   */
  constructor(ajaxProvider?: IAjaxProvider) {
    super("/performance-api", ajaxProvider);
  }

  /**
   * @param request 
   */
  executeScheduledJob(request?: ScheduledJobExecutionRequest, options?: QueryOptions) {
    // create query
    const url = `/ScheduledJob/ExecuteScheduledJob`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = request;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   */
  getScheduledJobManagerViewModel(options?: QueryOptions) {
    // create query
    const url = `/ScheduledJob/GetScheduledJobManagerViewModel`;
    let query = this.createQuery<ScheduledJobManagerViewModel>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    query = query.withOptions(options);

    return query.firstAsync();
  }
}

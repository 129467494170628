export default class VueMultiSelectEnData {
  static data = {
    selectLabel: 'Select',
    selectGroupLabel: 'Press "Enter / Enter" key to select group',
    selectedLabel: 'Selected',
    deselectLabel: 'Remove',
    deselectGroupLabel: 'Press the "Enter / Enter" key to deselect the group',
    limitText: 'and more _{count}_',
    noResult: 'There were no results. Change your search criteria and try again',
    noOptions: 'Empty list',
    maxElements: 'You can select up to _{max}_. Deselect at least one selection to reselect'
  }
}

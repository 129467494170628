import './clarification-text-popover.scss'

import { Component, Vue } from 'vue-property-decorator'

import { createApi } from '../../common'
import { ClarificationTextTranslatedDto } from '../../metadata/hrnext-performance-service-api'
import { ClarificationTextApi } from '../../metadata/hrnext-performance-service-api/ClarificationTextApi'

@Component({
  template: require('./clarification-text-popover.pug')
})
export class ClarificationTextPopoverComponent extends Vue {
  clearificationTextApi: ClarificationTextApi
  clarificationTextTranslatedDto: ClarificationTextTranslatedDto = {}
  constructor() {
    super()
    this.clearificationTextApi = createApi(ClarificationTextApi)
  }
  async created(): Promise<void> {
    const translated = await this.clearificationTextApi
      .listTranslated()
      .where(x => x.isActive == true && x.isApproveNeeded == true)
      .toArrayAsync()
    this.clarificationTextTranslatedDto = await translated.asQueryable().firstOrDefaultAsync()
  }
}

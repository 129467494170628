/**
 * HRNext Performance WebApi
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import {
  ActivePeriodTypeOfCurrentPerformanceFormViewModel,
  ApprovalCountsDto,
  ApprovalFlowCountDto,
  ApprovalFlowReportDto,
  ApprovalStatusReportDto,
  AssignPerformanceFormConditionalResult,
  CommonFormRequestDto,
  CommonRequestDto,
  ComparisonAnalysisSearchDto,
  CompetenceLightInformationDto,
  CurrentPerformanceFormStatusMetricDto,
  CurrentPerformanceFormSummaryViewModel,
  DashboardRequestDto,
  EmployeeDashboardViewModel,
  EmployeeFilterDto,
  EmployeeFilterResultDto,
  EmployeePerformanceSurveyProfileViewModel,
  EmployeePeriodReportDto,
  EmployeePeriodsDto,
  PerformanceFormDto,
  PerformanceFormEditViewModel,
  PerformanceFormEmployeeDto,
  PerformanceFormEmployeeJobDto,
  PerformanceFormLightDto,
  PerformanceFormRejectionDto,
  PerformanceFormSummaryViewModel,
  TargetOfferResultDto,
  PerformanceFormTargetOfferDto,
  PublishedAnnouncementDto,
  StatusMetricRequestDto,
  TeamMemberPerformanceFormAndScoreQueryDto,
  TeamMemberPerformanceFormQueryDto,
  TeamMemberQueryDto,
  TeamPerformanceFormStatusMetricQueryDto,
  YearParameter
} from './models';
import { LinqService, QueryOptions } from 'linquest';

import { IAjaxProvider } from 'jinqu';

/**
 * PerformanceFormApi - API class
 */
export class PerformanceFormApi extends LinqService {

  /**
   * Creates a new PerformanceFormApi class.
   *
   * @param ajaxProvider The Linquest Ajax Provider to inject. When not specified, default Fetch provider will be used.
   */
  constructor(ajaxProvider?: IAjaxProvider) {
    super("/performance-api", ajaxProvider);
  }

  /**
   * @param dto 
   */
  addOrUpdateAsync(dto?: PerformanceFormDto, options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/AddOrUpdateAsync`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = dto;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param formId 
   */
  approveFormStep(formId?: string, options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/ApproveFormStep`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'formId', value: formId && formId.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param performanceFormEmployeeDto 
   */
  createPerformanceFormsBulkAsync(performanceFormEmployeeDto?: Array<PerformanceFormEmployeeDto>, options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/CreatePerformanceFormsBulkAsync`;
    let query = this.createQuery<AssignPerformanceFormConditionalResult>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = performanceFormEmployeeDto;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param id 
   */
  deleteAsync(id?: string, options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/DeleteAsync`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param formId 
   */
  deletePerformanceFormRevisionAsync(formId?: string, options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/DeletePerformanceFormRevisionAsync`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'formId', value: formId && formId.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param employeeFilterDto 
   */
  exportPerformanceFormReport(employeeFilterDto?: EmployeeFilterDto, options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/ExportPerformanceFormReport`;
    let query = this.createQuery<EmployeeFilterResultDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = employeeFilterDto;
    query = query.withOptions(options);

    return query;
  }

  /**
   * @param year 
   * @param employeeId 
   */
  getActivePeriodTypeOfCurrentPerformanceForm(year?: number, employeeId?: string, options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/GetActivePeriodTypeOfCurrentPerformanceForm`;
    let query = this.createQuery<ActivePeriodTypeOfCurrentPerformanceFormViewModel>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'year', value: year && year.toString() });
    // set query parameters
    options.params.push({ key: 'employeeId', value: employeeId && employeeId.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   */
  getAnnouncementList(options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/GetAnnouncementList`;
    let query = this.createQuery<PublishedAnnouncementDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    query = query.withOptions(options);

    return query;
  }

  /**
   */
  getApprovaFlowChartOfCurrentUser(options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/GetApprovaFlowChartOfCurrentUser`;
    let query = this.createQuery<ApprovalFlowCountDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    query = query.withOptions(options);

    return query;
  }

  /**
   */
  getApprovalStatusChartOfCurrentUser(options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/GetApprovalStatusChartOfCurrentUser`;
    let query = this.createQuery<ApprovalCountsDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   */
  getApprovalStatusReport(options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/GetApprovalStatusReport`;
    let query = this.createQuery<ApprovalStatusReportDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    query = query.withOptions(options);

    return query;
  }

  /**
   * @param id 
   */
  getAsync(id?: string, options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/GetAsync`;
    let query = this.createQuery<PerformanceFormDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param formId 
   * @param employeeId 
   */
  getCompetencesByPermormanceId(formId?: string, employeeId?: string, options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/GetCompetencesByPermormanceId`;
    let query = this.createQuery<CompetenceLightInformationDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'formId', value: formId && formId.toString() });
    // set query parameters
    options.params.push({ key: 'employeeId', value: employeeId && employeeId.toString() });
    query = query.withOptions(options);

    return query;
  }

  /**
   * @param formId 
   */
  getCrossCompanyMemberPerformanceFormSummaryViewModel(formId?: string, options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/GetCrossCompanyMemberPerformanceFormSummaryViewModel`;
    let query = this.createQuery<PerformanceFormSummaryViewModel>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'formId', value: formId && formId.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   */
  getEmployeeApprovalFlowReport(options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/GetEmployeeApprovalFlowReport`;
    let query = this.createQuery<ApprovalFlowReportDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    query = query.withOptions(options);

    return query;
  }

  /**
 * @param yearParams 
 */
  getEmployeeDashboard(data?: DashboardRequestDto, options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/GetEmployeeDashboard`;
    let query = this.createQuery<EmployeeDashboardViewModel>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = data;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
* @param yearParams 
*/
  getEmployeePerformanceProfile(data?: DashboardRequestDto, options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/GetEmployeePerformanceProfile`;
    let query = this.createQuery<EmployeeDashboardViewModel>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = data;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param formId 
   */
  getEmployeePerformanceSurveyProfile(formId?: string, options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/GetEmployeePerformanceSurveyProfile`;
    let query = this.createQuery<EmployeePerformanceSurveyProfileViewModel>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'formId', value: formId && formId.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   */
  getEmployeePeriodReport(options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/GetEmployeePeriodReport`;
    let query = this.createQuery<EmployeePeriodReportDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    query = query.withOptions(options);

    return query;
  }

  /**
   */
  getEmployeePeriodsChartOfCurrentUser(options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/GetEmployeePeriodsChartOfCurrentUser`;
    let query = this.createQuery<EmployeePeriodsDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    query = query.withOptions(options);

    return query;
  }

  /**
   * @param request
   */
  getPerformanceFormEditViewModel(request?: CommonFormRequestDto, options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/GetPerformanceFormEditViewModel`;
    let query = this.createQuery<PerformanceFormEditViewModel>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = request;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param formId 
   * @param employeeId 
   */
  getPermormanceFormByEmployeeToManager(formId?: string, employeeId?: string, options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/GetPermormanceFormByEmployeeToManager`;
    let query = this.createQuery<PerformanceFormDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'formId', value: formId && formId.toString() });
    // set query parameters
    options.params.push({ key: 'employeeId', value: employeeId && employeeId.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param comparisonAnalysiseSearchDto 
   */
  getTeamPerformanceFormAndScoresOfCurrentUser(comparisonAnalysiseSearchDto?: ComparisonAnalysisSearchDto, options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/GetTeamPerformanceFormAndScoresOfCurrentUser`;
    let query = this.createQuery<TeamMemberPerformanceFormAndScoreQueryDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = comparisonAnalysiseSearchDto;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param id 
   */
  getTranslatedAsync(id?: string, options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/GetTranslatedAsync`;
    let query = this.createQuery<PerformanceFormDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param yearParams 
   */
  hasAnyRecordInApprovalProcess(yearParams?: YearParameter, options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/HasAnyRecordInApprovalProcess`;
    let query = this.createQuery<boolean>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = yearParams;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param yearParams 
   */
  hasCurrentUserGotAnyRevision(yearParams?: YearParameter, options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/HasCurrentUserGotAnyRevision`;
    let query = this.createQuery<boolean>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = yearParams;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   */
  list(options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/List`;
    let query = this.createQuery<PerformanceFormDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    query = query.withOptions(options);

    return query;
  }

  /**
   */
  listLight(options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/ListLight`;
    let query = this.createQuery<PerformanceFormLightDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    query = query.withOptions(options);

    return query;
  }

  /**
   */
  listTeamOfCurrentUser(options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/ListTeamOfCurrentUser`;
    let query = this.createQuery<TeamMemberQueryDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    query = query.withOptions(options);

    return query;
  }

  /**
* @param employeeId 
*/
  getTargetOffers(employeeId?: string, options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/GetTargetOffers`;
    let query = this.createQuery<TargetOfferResultDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'employeeId', value: employeeId && employeeId.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param year 
   */
  listTeamPerformanceFormOfCurrentUser(yearParams?: YearParameter, options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/ListTeamPerformanceFormOfCurrentUser`;
    let query = this.createQuery<TeamMemberPerformanceFormQueryDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = yearParams;
    query = query.withOptions(options);

    return query;
  }

  /**
   * @param yearParams 
   */
  listTeamPerformanceFormStatusMetric(yearParams?: YearParameter, options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/ListTeamPerformanceFormStatusMetric`;
    let query = this.createQuery<TeamPerformanceFormStatusMetricQueryDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = yearParams;
    query = query.withOptions(options);

    return query;
  }

  /**
   */
  listTranslated(options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/ListTranslated`;
    let query = this.createQuery<PerformanceFormDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    query = query.withOptions(options);

    return query;
  }

  /**
   * @param formId 
   */
  moveApprovalToNextStep(formId?: string, options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/MoveApprovalToNextStep`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'formId', value: formId && formId.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param formId 
   * @param rejectionNote 
   */
  moveApprovalToPreviousStep(formId?: string, rejectionNote?: string, options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/MoveApprovalToPreviousStep`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'formId', value: formId && formId.toString() });
    // set query parameters
    options.params.push({ key: 'rejectionNote', value: rejectionNote && rejectionNote.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param formId 
   */
  recallForm(formId?: string, options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/RecallForm`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'formId', value: formId && formId.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param formId 
   * @param rejectionNote 
   */
  rejectFormStep(formId?: string, rejectionNote?: string, options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/RejectFormStep`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'formId', value: formId && formId.toString() });
    // set query parameters
    options.params.push({ key: 'rejectionNote', value: rejectionNote && rejectionNote.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param formRejectionDto 
   */
  rejectFormStepWithDetails(formRejectionDto?: PerformanceFormRejectionDto, options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/RejectFormStepWithDetails`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = formRejectionDto;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param employeeId 
   * @param currentFormTemplateId 
   */
  removeExistingCurrentTemplate(employeeId?: string, currentFormTemplateId?: string, options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/RemoveExistingCurrentTemplate`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'employeeId', value: employeeId && employeeId.toString() });
    // set query parameters
    options.params.push({ key: 'currentFormTemplateId', value: currentFormTemplateId && currentFormTemplateId.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param dto 
   */
  saveAsync(dto?: PerformanceFormDto, options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/SaveAsync`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = dto;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param performanceFormEmployeeDto 
   */
  startPerformanceFormAssingJobAsync(performanceFormEmployeeDto?: Array<PerformanceFormEmployeeJobDto>, options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/StartPerformanceFormAssingJobAsync`;
    let query = this.createQuery<boolean>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = performanceFormEmployeeDto;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
 * @param yearParams 
 */
  getCurrentPerformanceFormTargetStatusMetric(data?: StatusMetricRequestDto, options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/GetCurrentPerformanceFormTargetStatusMetric`;
    let query = this.createQuery<CurrentPerformanceFormStatusMetricDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = data;
    query = query.withOptions(options);

    return query.toArrayAsync();
  }

  /**
 * @param yearParams 
 */
  getCurrentPerformanceFormSummary(yearParams?: YearParameter, options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/GetCurrentPerformanceFormSummary`;
    let query = this.createQuery<CurrentPerformanceFormSummaryViewModel>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = yearParams;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param periodId 
   */
  anyPerformanceFormByPeriod(periodId?: string, options?: QueryOptions) {
    // create query
    const url = `/PerformanceForm/AnyPerformanceFormByPeriod`;
    let query = this.createQuery<boolean>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'periodId', value: periodId && periodId.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

}

import axios, { AxiosInstance } from 'axios'

import { DocumentApi } from '../metadata/hrnext-document-service-api/DocumentApi'
import { DocumentListResponseDocumentTypeEnum } from '../metadata/hrnext-document-service-api/models'
import { createApi } from '.'
import { createRouter } from '../main/router'
import { handleErrorAxiosInterceptors } from '../main/axios-interceptors'

export class DocumentManagementService {
  private baseAddress: string
  private axios: AxiosInstance

  constructor() {
    const router = createRouter()
    const documentApi = createApi(DocumentApi)
    this.baseAddress = documentApi.baseAddress // Get already compiled address by Webpack
    this.axios = axios.create({
      baseURL: this.baseAddress,
      headers: {
        'Content-type': 'application/json'
      }
    })

    this.axios.interceptors.response.use(response => response, handleErrorAxiosInterceptors(router))
  }

  async uploadBlob(
    documentType: DocumentListResponseDocumentTypeEnum,
    referenceId: string,
    blob: Blob,
    fileName: string
  ): Promise<void> {
    const formData = new FormData()
    formData.append('documentType', documentType)
    formData.append('referenceId', referenceId)

    formData.append('files', blob, fileName)

    await this.axios.post('/Document/Upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  async upload(
    documentType: DocumentListResponseDocumentTypeEnum,
    referenceId: string,
    files: Blob | Blob[]
  ): Promise<void> {
    const formData = new FormData()
    formData.append('documentType', documentType)
    formData.append('referenceId', referenceId)

    if (files instanceof Blob) {
      formData.append('files', files)
    } else {
      for (const file of files) {
        formData.append('files', file)
      }
    }
    await this.axios.post('/Document/Upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  async download(documentMetadataId: string): Promise<void> {
    const response = await this.axios.get(`/Document/Download?documentMetadataId=${documentMetadataId}`, {
      responseType: 'arraybuffer'
    })

    // To access this, we had to add this CorsOptions to Api:
    // .WithExposedHeaders("X-Suggested-Filename")
    const fileName = response.headers['x-suggested-filename'] ?? 'file'

    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
  }
}

import {
  CompanyFeatureModuleInfoViewModel,
  OkrPeriodDto,
  UserModuleApi,
  YearParameter
} from '../metadata/hrnext-performance-service-api'

import { DocumentApi } from '../metadata/hrnext-document-service-api/DocumentApi'
import { UserModuleEmployeeApi } from '../metadata/hrnext-performance-service-api/UserModuleEmployeeApi'
import VueI18n from 'vue-i18n'
import { createApi } from './api-factory'
import { createGuid } from './helper'

export class Globals {
  // Global Static Vars Begin
  public static IsLoadingDisabled = false
  public static i18n: VueI18n = null
  public static readonly turkishLocaleCode = 'tr'
  public static readonly SecurityItemsSourceName = 'perfxclient'
  public static readonly NotificationSourceAppType = 1
  // Global Static Vars End

  // Global Feature Module Static Vars Begin
  public static CompanyFeatureModulesInfo: CompanyFeatureModuleInfoViewModel = {}

  public static readonly FeatureModuleEval360Code = 'threesixtyfeaturemodule'
  public static isFeatureModuleEval360Allowed = false
  public static readonly FeatureModuleEval360Enum = '2'

  public static readonly FeatureModulOkrCode = 'okrfeaturemodule'
  public static isFeatureModuleOkrAllowed = false
  public static readonly FeatureModulOkrEnum = '1'

  public static readonly FeatureModuleAnnouncementCode = 'announcementfeaturemodule'
  public static isFeatureAnnouncementModuleAllowed = false

  public static readonly FeatureModuleScheduledJobsCode = 'scheduledjobfeaturemodule'
  public static isFeatureScheduledJobsAllowed = false

  public static readonly FeatureModuleTotalStatisticsCode = 'totalstatisticsfeaturemodule'
  public static isFeatureTotalStatisticsAllowed = false

  public static readonly FeatureModuleAccessRequestCode = 'accessrequestfeaturemodule'
  public static isFeatureAccessRequestAllowed = false
  //  Global Feature Module Static Vars End

  // Feature Toggles Begin
  public static readonly CompetenceDetailToggle = 'CompetenceDetail'
  public static readonly EvaluationToggle = 'Evaluation'
  public static readonly CorrelatedTargetToggle = 'CorrelatedTarget'
  public static readonly OtherTargetUnitCompletion = 'OtherTargetUnitCompletion'
  public static readonly ComparisonAnalysis = 'ComparisonAnalysis'
  public static readonly Evaluation360 = 'Evaluation360'
  public static readonly IsOperationYearSystemActive = 'IsOperationYearSystemActive'
  public static readonly SystemMenuInAdminSidebar = 'SystemMenuInAdminSidebar'
  public static readonly CompanyCustomizationParameter = 'CompanyCustomizationParameter'

  public static readonly IsOpenPerformanceFormsWithJob = 'IsOpenPerformanceFormsWithJob'
  public static readonly IsNewHomePageVisible = 'IsNewHomePageVisible'
  public static readonly IsTargetOfferAIOpen = 'IsTargetOfferAIOpen'
  public static readonly IsCorrelationPopupOpen = 'IsCorrelationPopupOpen'
  // Feature Toggles End

  // Session/Local Storage Vars Begin
  public static readonly FeatureToggleCacheKey = 'FeatureToggleCache'
  public static readonly EmployeeInfoCacheKey = 'EmployeeInfoCache'
  public static readonly LocationsCacheKey = 'LocationsCache'
  public static readonly PerformanceFormTemplateGroupsCacheKey = 'PerformanceFormTemplateGroupsCache'
  public static readonly PerformanceFormTemplatesCacheKey = 'PerformanceFormTemplatesCache'
  public static readonly ComparisonGroupsCacheKey = 'ComparisonGroupsCache'
  public static readonly DepartmentsCacheKey = 'DepartmentsCache'
  public static readonly PositionGroupsCacheKey = 'PositionGroupsCache'
  public static readonly EducationsCacheKey = 'EducationsCache'
  public static readonly WageLevelsCacheKey = 'WageLevelsCache'
  public static readonly CollarsCacheKey = 'CollarsCache'
  public static readonly EmployeeLevelsCacheKey = 'EmployeeLevelsCache'
  public static readonly EmployeeLevelsFor360CacheKey = 'EmployeeLevelsFor360CacheKey'
  public static readonly WorkingStatusesCacheKey = 'WorkingStatusesCache'
  public static readonly CompanyFeatureModulesInfoCacheKey = 'CompanyFeatureModulesInfoCache'
  public static readonly SidebarMenuInfoCacheKey = 'SidebarMenuInfoCache'
  public static readonly FunctionsCacheKey = 'FunctionsCache'

  // SessionStorage Vars End

  public static UserRequestCorrelationId

  public static setUserRequestCorrelationId(): void {
    Globals.UserRequestCorrelationId = createGuid()
  }

  public static getFlexYearTest(): boolean | undefined {
    if (window.location.href.includes('fye=true') || window.location.href.includes('fye=false')) {
      const isActive = window.location.href.includes('fye=true')
      sessionStorage.setItem('FlexYearTest', isActive ? 'true' : 'false')
      return isActive
    } else if (sessionStorage.getItem('FlexYearTest')) return sessionStorage.getItem('FlexYearTest') == 'true'
    return undefined
  }

  public static readonly ActiveYearParamsKey = btoa('ActiveYearParams')
  public static readonly ActiveOkrPeriodParamsKey = btoa('ActiveOkrPeriodParams')
  public static readonly DefaultYearOptionKey = btoa('DefaultYearOptionKey')

  public static setActiveYearParams(model: YearParameter): void {
    if (model) {
      const prefix = this.getFlexYearTest() == undefined ? '' : this.getFlexYearTest() ? 'F:' : 'Y:'
      sessionStorage.setItem(prefix + this.ActiveYearParamsKey, btoa(JSON.stringify(model)))
    }
  }

  public static setActiveOkrPeriodParams(periodId: string): void {
    if (periodId) {
      sessionStorage.setItem(this.ActiveOkrPeriodParamsKey, btoa(JSON.stringify(periodId)))
    }
  }

  public static getActiveYearParams(): YearParameter {
    const prefix = this.getFlexYearTest() == undefined ? '' : this.getFlexYearTest() ? 'F:' : 'Y:'
    const value = sessionStorage.getItem(prefix + this.ActiveYearParamsKey)
    if (value) {
      const data = JSON.parse(atob(value))
      data.startDate = new Date(data.startDate) ?? undefined
      data.endDate = new Date(data.endDate) ?? undefined
      return data
    }
    return undefined
  }

  public static getActiveOkrPeriodParams(): string {
    const value = sessionStorage.getItem(this.ActiveOkrPeriodParamsKey)
    if (value) {
      const data = JSON.parse(atob(value))
      return data
    }
    return undefined
  }

  public static setDefaultYearOption(option = ''): void {
    const prefix = this.getFlexYearTest() == undefined ? '' : this.getFlexYearTest() ? 'F:' : 'Y:'
    sessionStorage.setItem(prefix + this.DefaultYearOptionKey, btoa(option))
  }

  public static getDefaultYearOption(): string {
    const prefix = this.getFlexYearTest() == undefined ? '' : this.getFlexYearTest() ? 'F:' : 'Y:'
    const value = sessionStorage.getItem(prefix + this.DefaultYearOptionKey)
    return atob(value ?? '')
  }

  public static setIsLoadingDisabled(value: boolean): void {
    Globals.IsLoadingDisabled = value
  }

  public static getIsLoadingDisabled(): boolean {
    return Globals.IsLoadingDisabled
  }

  public static setFeatureToggleCache(toggle: string, toggleVal: boolean): void {
    const bas64Cachekey = btoa(Globals.FeatureToggleCacheKey)
    const val = Globals.getStorageItem(bas64Cachekey)
    let obj = {}
    if (val) {
      obj = val
    }
    obj[toggle] = toggleVal
    // Expires in 1 hour
    Globals.setStorageItem(bas64Cachekey, obj, 60 * 60)
  }

  public static getFeatureToggleCache(toggle: string): any {
    const bas64Cachekey = btoa(Globals.FeatureToggleCacheKey)
    //const val = sessionStorage.getItem(bas64Cachekey)
    const val = Globals.getStorageItem(bas64Cachekey)
    if (val) {
      const obj = val
      if (obj && obj[toggle]) {
        return obj[toggle]
      }
      return null
    }
  }

  // EmployeeBaseInfo Cache
  public static getEmployeeBaseInfo(employeeApi): any {
    return Globals.getEntityDataFromCache(Globals.EmployeeInfoCacheKey, async () => {
      return await employeeApi.getCurrentEmployeeBaseInfo()
    })
  }

  public static async getEntityDataFromCache(cacheKey: string, apiFunc: () => Promise<any>): Promise<any> {
    const bas64Cachekey = btoa(cacheKey)
    const val = Globals.getStorageItem(bas64Cachekey)
    if (val) {
      return val
    } else {
      const entityData = await apiFunc()
      // Expires in 3 hours
      Globals.setStorageItem(bas64Cachekey, entityData, 60 * 60 * 3)
      return entityData
    }
  }

  // CompanyFeatureModules
  public static async checkIfFeatureModuleAllowed(featureModuleCode, employeeId = null): Promise<boolean> {
    const companyFeatureModulesInfo: CompanyFeatureModuleInfoViewModel = Globals.CompanyFeatureModulesInfo

    if (companyFeatureModulesInfo.companyFeatureModules) {
      const featureModule = await companyFeatureModulesInfo.companyFeatureModules.find(
        x => x.featureModuleCode === featureModuleCode
      )

      if (featureModule) {
        if (employeeId != null) {
          // calisan bazlı feature module kontrolu yapılıyor
          return await Globals.checkIfUserAuthorizedToFeatureModule(featureModuleCode, employeeId)
        } else {
          return featureModule.isAllowed
        }
      }
    }
    return false
  }

  // CompanyFeatureModules Cache
  public static getCompanyFeatureModulesInfo(userProxyApi): any {
    return Globals.getCompanyFeatureModulesFromCache(Globals.CompanyFeatureModulesInfoCacheKey, async () => {
      return await userProxyApi.getCurrentCompanyFeatureModules()
    })
  }

  public static async getCompanyFeatureModulesFromCache(cacheKey: string, apiFunc: () => Promise<any>): Promise<any> {
    const bas64Cachekey = btoa(cacheKey)
    const val = Globals.getStorageItem(bas64Cachekey)
    if (val) {
      return val
    } else {
      const entityData = await apiFunc()
      // Expires in 1 hour
      Globals.setStorageItem(bas64Cachekey, entityData, 60 * 60 * 1)
      return entityData
    }
  }

  public static setStorageItem(key: string, value: any, ttl: number, useLocalStorage = false): void {
    const item = {
      value: value,
      expiry: ttl ? Date.now() + ttl * 1000 : null // In Seconds
    }
    if (useLocalStorage) {
      localStorage.setItem(key, btoa(unescape(encodeURIComponent(JSON.stringify(item)))))
    } else {
      sessionStorage.setItem(key, btoa(unescape(encodeURIComponent(JSON.stringify(item)))))
    }
  }

  public static getStorageItem(key, useLocalStorage = false): any {
    let itemJson = ''
    if (useLocalStorage) {
      itemJson = localStorage.getItem(key)
    } else {
      itemJson = sessionStorage.getItem(key)
    }

    if (!itemJson) return null

    const item = JSON.parse(decodeURIComponent(escape(atob(itemJson))))

    if (item.expiry && Date.now() > item.expiry) {
      if (useLocalStorage) {
        localStorage.removeItem(key)
      } else {
        sessionStorage.removeItem(key)
      }

      return null
    }
    return item.value
  }

  public static clearStorageItem(key: string, useLocalStorage = false): void {
    if (useLocalStorage) {
      localStorage.removeItem(key)
    } else {
      sessionStorage.removeItem(key)
    }
  }

  public static getDocumentShowByFileNameUrl(fileName: string): string {
    const documentApi = createApi(DocumentApi)
    const url = documentApi.baseAddress + '/Document/ShowByFileName?fileName=' + fileName
    return url
  }

  public static async checkIfUserAuthorizedToFeatureModule(
    featureModuleCode: string,
    employeeId: string
  ): Promise<boolean> {
    let userModule = '1' // 1. okr,2, 360
    if (featureModuleCode == 'threesixtyfeaturemodule') {
      userModule = '2'
    }
    const userModuleApi = createApi(UserModuleApi)
    const moduleUserForAllcompany = await userModuleApi.getUserModuleByModuleType(userModule)

    if (moduleUserForAllcompany.useForAllCompany) {
      //true oldugu durumda , company feature modul 'u herkes icin kulanıyor. employee bazinda bakmaya gerek yok.
      return true
    } else {
      const userModuleEmployeeApi = createApi(UserModuleEmployeeApi)
      const useForSpesificEmployee = await userModuleEmployeeApi.ifEmployeeAuthorizedToFeatureModule(
        employeeId,
        moduleUserForAllcompany.id
      )
      return useForSpesificEmployee
    }
  }
}

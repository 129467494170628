import { createApi } from '../common'
import { Globals } from '../common/globals'
import { ConfigApi } from '../metadata/hrnext-performance-service-api/ConfigApi'

export class ClientConfiguration {
  // TODO: clientConfig için bir type vermeye çalıştım ancak karar veremedim
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static clientConfig: any = process.env
}

//TODO: service config (consul) bekliyor
export const setValuesFromConfigServer = async (): Promise<void> => {
  try {
    const resultJson = Globals.getStorageItem(btoa('ClientConfig'))
    let result = {}
    if (resultJson) {
      result = resultJson
    } else {
      const api: ConfigApi = createApi(ConfigApi)
      result = await api.getClientSettings()

      let str = JSON.stringify(result)
      str = str.replace(/"env":/g, '"ENV":')
      str = str.replace(/"nodE_ENV":/g, '"NODE_ENV":')
      str = str.replace(/"debuG_MODE":/g, '"DEBUG_MODE":')
      str = str.replace(/"basE_API_URL":/g, '"BASE_API_URL":')
      str = str.replace(/"identityserveR_AUTHORITY":/g, '"IDENTITYSERVER_AUTHORITY":')
      str = str.replace(/"identityserveR_CLIENT_ID":/g, '"IDENTITYSERVER_CLIENT_ID":')
      str = str.replace(/"identityserveR_REDIRECT_URI":/g, '"IDENTITYSERVER_REDIRECT_URI":')
      str = str.replace(/"identityserveR_RESPONSE_TYPE":/g, '"IDENTITYSERVER_RESPONSE_TYPE":')
      str = str.replace(/"identityserveR_SCOPES":/g, '"IDENTITYSERVER_SCOPES":')
      str = str.replace(/"identityserveR_POST_LOGOUT_REDIRECT_URI":/g, '"IDENTITYSERVER_POST_LOGOUT_REDIRECT_URI":')
      str = str.replace(/"clienT_APP_BASE_PATH":/g, '"CLIENT_APP_BASE_PATH":')
      str = str.replace(/"identityadmiN_BASE_URL":/g, '"IDENTITYADMIN_BASE_URL":')
      str = str.replace(/"clienT_LOGGER_ENABLED":/g, '"CLIENT_LOGGER_ENABLED":')
      result = JSON.parse(str)
      Globals.setStorageItem(btoa('ClientConfig'), result, 60 * 60 * 3)
    }
    ClientConfiguration.clientConfig = result || ClientConfiguration.clientConfig
  } catch (error) {
    console.log(error)
  }
}

export class LanguageDefinition {
  public languageCountryCode: string
  public languageName: string
  public languageIconPath?: string
  public displayOrder: number
  public static GetAllDefinedLanguages(): Map<string, LanguageDefinition> {
    const definitions: Map<string, LanguageDefinition> = new Map([
      [
        'tr',
        {
          languageCountryCode: 'tr',
          languageName: 'Türkçe',
          displayOrder: 1,
          languageIconPath: '/assets/img/flags/tr.png'
        }
      ],
      [
        'en',
        {
          languageCountryCode: 'en',
          languageName: 'English',
          displayOrder: 2,
          languageIconPath: '/assets/img/flags/en.png'
        }
      ],
      [
        'es',
        {
          languageCountryCode: 'es',
          languageName: 'Español',
          displayOrder: 3,
          languageIconPath: '/assets/img/flags/es.png'
        }
      ],
      [
        'fr',
        {
          languageCountryCode: 'fr',
          languageName: 'French',
          displayOrder: 4,
          languageIconPath: '/assets/img/flags/fr.png'
        }
      ]
    ])
    return definitions
  }
}

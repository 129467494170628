import '../sass/main.scss'
import 'vue-multiselect-multilanguage/dist/vue-multiselect.min.css'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import '../common/logging'
import 'vue-multiselect-multilanguage/dist/vue-multiselect.min.css'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import 'vue-croppa/dist/vue-croppa.css'
import './componentHook'

import { ClientConfiguration, setValuesFromConfigServer } from './client-config'
import { EmployeeApi, TermsOfUseApi, UserProxyApi } from '../metadata/hrnext-performance-service-api'
import { Localizer, Multiselect } from 'vue-multiselect-multilanguage'
import { VClosePopover, VPopover, VTooltip } from 'v-tooltip'
import VeeValidate, { Validator } from 'vee-validate'
import VueRouter, { RouteConfig } from 'vue-router'
import { getUserCultureFromCookie, handleNotifyClose, setUserCultureOnCookie } from '../common/helper'
import { handleAuthAxiosInterceptors, handleVueAxiosInterceptors } from './axios-interceptors'
import { momentDefaultDateFormat, toastDurationInMs, toastSuccessDurationInMs } from '../common/constants'

import { AuthHelper } from '../common/auth-helper'
import { BaseComponent } from '../common/base-component'
import Buefy from 'buefy'
import { ClarificationTextApi } from '../metadata/hrnext-performance-service-api/ClarificationTextApi'
import { ClarificationTextPopoverComponent } from '../components/clarification-text-popover/clarification-text-popover'
import { Console } from 'console'
import Croppa from 'vue-croppa'
import { tr as DatePickerTR } from 'vuejs-datepicker/dist/locale'
import Datepicker from 'vuejs-datepicker'
import { DocumentListMultipleResponseDocumentTypeEnum } from '../metadata/hrnext-document-service-api/models'
import { DocumentManagementService } from '../common/documentmanagementservice'
import { Globals } from '../common/globals'
import HtmlEditButton from 'quill-html-edit-button'
import InfiniteLoading from 'vue-infinite-loading'
import { JL } from 'jsnlog'
import { LanguageDefinition } from '../common/languageDefinition'
import { Logger } from '../common/logging'
import MiniProfiler from '../common/mini-profiler/MiniProfiler'
import { MultilingualInputComponent } from '../components/multilingual-input/multilingual-input'
import { MultilingualWrapperComponent } from '../components/multilingual-wrapper/multilingual-wrapper'
import { MutlilingualTextAreaComponent } from '../components/multilingual-textarea/multilingual-textarea'
import { NavigationComponent } from '../components/navigation/navigation'
import Notiflix from 'notiflix'
import { PortalNavigationComponent } from '../components/portal-navigation/portal-navigation'
import { PortalSidebarMenuComponent } from '../components/portal-sidebar-menu/portal-sidebar-menu'
import Quill from 'quill'
import { SidebarMenuComponent } from '../components/sidebar-menu/sidebar-menu'
import { TermsOfUsePopoverComponent } from '../components/terms-of-use-popover/terms-of-use-popover'
import { TimelineComponent } from '../components/timeline/timeline'
import { ToggleBtnComponent } from '../components/toggle-btn/toggle-btn'
import { Treeselect } from '@riophae/vue-treeselect'
import { UserMenuComponent } from '../components/user-menu/user-menu'
import { UserService } from '../common/userservice'
import Vue from 'vue'
import VueExcelXlsx from 'vue-excel-xlsx'
import VueI18n from 'vue-i18n'
import VueMultiSelectEnData from '../i18n/vue-multiselect-en'
import VueMultiSelectTrData from '../i18n/vue-multiselect-tr'
import VueMultiSelectEsData from '../i18n/vue-multiselect-es'
import VueMultiSelectFrData from '../i18n/vue-multiselect-fr'
import { createApi } from '../common'
import { createRouter } from './router'
import excel from 'vue-excel-export'
import { handleEvents } from './eventHandler'
import moment from 'moment'
import tr from 'vee-validate/dist/locale/tr'

Quill.register('modules/htmlEditButton', HtmlEditButton)

Globals.setUserRequestCorrelationId()

// Localization Start
// https://kazupon.github.io/vue-i18n/

Vue.use(VueI18n)
Vue.component('terms-of-use-popover', TermsOfUsePopoverComponent)
Vue.component('clarification-text-popover', ClarificationTextPopoverComponent)
// import translations from '../i18n/translations'

const supportedLangDefs = LanguageDefinition.GetAllDefinedLanguages()
const supportedLangs = Array.from(supportedLangDefs.values())

function loadLocaleInfo(): any {
  const locales = require.context('../i18n', true, /(^|\/)[A-Za-z0-9_,\s-]+\.json$/i)
  const messages = {}

  locales.keys().forEach(key => {
    const reg = /\/([^/.]+)/gi

    const groups = []
    let result = null
    while ((result = reg.exec(key)) !== null) {
      groups.push(result[1])
    }

    let messageRef = messages
    for (let i = 0; i < groups.length - 1; i++) {
      let messageRefValue = messageRef[groups[i]]

      if (messageRefValue) {
        //
      } else {
        messageRef[groups[i]] = {}
        messageRefValue = messageRef[groups[i]]
      }

      messageRef = messageRefValue
    }
    messageRef[groups[groups.length - 1]] = locales(key)
  })
  return messages
}

const translations = loadLocaleInfo()

Globals.i18n = new VueI18n({
  locale: Globals.turkishLocaleCode,
  fallbackLocale: Globals.turkishLocaleCode,
  messages: translations
})
const i18n = Globals.i18n
// Localization End

/* eslint-disable @typescript-eslint/no-explicit-any */
// Main Start
// Main End

moment.locale(i18n.locale)

// Mini Profiler
//

// Layout Components Start
Vue.component('navigation', NavigationComponent)
Vue.component('sidebar-menu', SidebarMenuComponent)
Vue.component('portal-navigation', PortalNavigationComponent)
Vue.component('portal-sidebar-menu', PortalSidebarMenuComponent)
Vue.component('user-menu', UserMenuComponent)

// Layout Components End

// Page Components Start

Vue.component('toggle-btn', ToggleBtnComponent)
Vue.component('timeline', TimelineComponent)
Vue.component('v-popover', VPopover)
Vue.directive('tooltip', VTooltip)
Vue.directive('close-popover', VClosePopover)
Vue.component('multilingual-input', MultilingualInputComponent)
Vue.component('multilingual-textarea', MutlilingualTextAreaComponent)
Vue.component('multilingual-wrapper', MultilingualWrapperComponent)

// croppa

Vue.use(Croppa)
Vue.component('vue-croppa', Croppa)

//excel export

Vue.use(excel)

//xlsx excel export

Vue.use(VueExcelXlsx)

// Page Components End

// Logging Start

// Logging End

// Auth Helper Start

// Auth Helper End

// Buefy Start
// Next line commented to customize Buefy theme
// import 'buefy/dist/buefy.css'

Vue.use(Buefy, {
  defaultDateFormatter: date => {
    const targetDates = Array.isArray(date) ? date : [date]
    const formattedDates = targetDates.map(d => moment(d).format(momentDefaultDateFormat))
    return formattedDates.join(' - ')
  },
  defaultDateParser: input => moment(input, momentDefaultDateFormat).toDate(),
  defaultMonthNames: moment.months(),
  //TODO: Bu kısmı düzeltmek gerek.
  // Kısaltmalar için şöyle bir kullanım da var: weekdaysMin. Araştırılmalı.
  defaultDayNames: ['P', 'P', 'S', 'Ç', 'P', 'C', 'C'], //moment.weekdays(),
  defaultFirstDayOfWeek: 1
})
// Buefy End

// Multiselect Start

Vue.component('multiselect', Multiselect)
// Multiselect End

// Treeselect Start

Vue.component('treeselect', Treeselect)
// Treeselect End

// Datepicker Start

Vue.component('datepicker', Datepicker)
// Datepicker End

// VeeValidate Start

Vue.use(VeeValidate)
// TODO: Localization
Validator.localize('tr', tr)
// VeeValidate End

// Infinite loader Start

Vue.use(InfiniteLoading, {
  props: {
    spinner: 'circles'
  }
})
// Infinite loader End

// Services Start

// Services End

// Local Vars Start
const appName = 'HRNext.Performance.Client'
let router: VueRouter = createRouter()
let authHelper: AuthHelper
// Local Vars End

//Notifilix Init
Notiflix.Loading.init({
  backgroundColor: 'rgba(30,30,30, 0.8)',
  cssAnimation: true,
  customSvgUrl: '../assets/img/loader.svg',
  svgSize: '180px'
})
Notiflix.Loading.custom(Globals.i18n.t('shared.pleaseWait'))

Notiflix.Notify.init({ useIcon: true })

const setAuthHelper = function(
  config: any,
  applicationName: string,
  setSecurityItems: boolean,
  setUserRoles: boolean
): void {
  authHelper = new AuthHelper(
    {
      authority: config.IDENTITYSERVER_AUTHORITY,
      // eslint-disable-next-line @typescript-eslint/camelcase
      client_id: config.IDENTITYSERVER_CLIENT_ID,
      // eslint-disable-next-line @typescript-eslint/camelcase
      redirect_uri: config.IDENTITYSERVER_REDIRECT_URI,
      // eslint-disable-next-line @typescript-eslint/camelcase
      response_type: config.IDENTITYSERVER_RESPONSE_TYPE,
      scope: config.IDENTITYSERVER_SCOPES,
      // eslint-disable-next-line @typescript-eslint/camelcase
      post_logout_redirect_uri: config.IDENTITYSERVER_POST_LOGOUT_REDIRECT_URI
    },
    {
      applicationName: applicationName,
      setSecurityItems: setSecurityItems,
      setUserRoles: setUserRoles,
      byPassRoutePermissionCheck: false,
      basePath: process.env.CLIENT_APP_BASE_PATH || ClientConfiguration.clientConfig.CLIENT_APP_BASE_PATH,
      securityItemsSourceName: Globals.SecurityItemsSourceName
    },
    new UserService()
  )
}

const handleAdminPanel = function(app: Vue, path: string, routes: RouteConfig[]): void {
  if (app.$root.$data) {
    app.$data.showAdminPortal = routes.asQueryable().any(x => x.path === path && x['isAdminRoute'] === true)
  }
}

const init = async (): Promise<any> => {
  handleAuthAxiosInterceptors(router)
  handleEvents()
  await setValuesFromConfigServer()

  setAuthHelper(ClientConfiguration.clientConfig, appName, true, false)

  if (
    ClientConfiguration.clientConfig.ENV === 'development' ||
    ClientConfiguration.clientConfig.ENV === 'development-integration'
  ) {
    Vue.component('miniprofiler', MiniProfiler)
  }

  JL.setOptions({
    enabled: ClientConfiguration.clientConfig.CLIENT_LOGGER_ENABLED
  })

  router = createRouter()
  router.beforeEach(async (to, from, next) => {
    authHelper.beforeEach(router, to, from, next)
    handleAdminPanel(router.app, to.path, router.app.$router['options'].routes)
    Logger.ClientLogger.info({
      event: 'user_page_route',
      from: from.fullPath,
      to: to.fullPath,
      userinfo: Vue.prototype.$userInfo
    })
  })

  Vue.mixin({
    methods: {
      // canSee: function(credential: string): boolean {
      //   const securityProvider = getSecurityProvider()
      //   return securityProvider.canSee('HRNext.User.Api.Controllers.' + credential)
      // }
    },
    data() {
      return {
        defaultLanguage: DatePickerTR
      }
    }
  })

  const miniProfilerSrc: string = process.env.BASE_API_URL.replace(/\/*$/g, '') + '/profiler/'

  // tslint:disable-next-line:no-unused-expression
  const vm = new Vue({
    i18n,
    el: '#app-main',
    router: router,
    data: {
      supportedLangs: supportedLangs,
      envName: ClientConfiguration.clientConfig.ENV,
      // MiniProfiler Vars
      mpsrc: miniProfilerSrc,
      mpscriptsrc: `${miniProfilerSrc}includes.min.js`,
      mpcsssrc: `${miniProfilerSrc}includes.min.css`,
      //
      isBusyCount: 0,
      isBusyAuthenticating: true,
      isAuthenticated: false,
      userInfo: {},
      canAccessAdmin: false,
      showAdminPortal: false,
      showUploadProfilePhotoModal: false,
      termsOfUsePopoverModalActive: false,
      clarificationTextPopoverModalActive: false,
      isFeatureModuleEval360Allowed: false,
      isFeatureModuleOkrAllowed: false,
      localeMonthNames: [],
      localeDayNames: [],
      profilePhotoUploadingModal: null,
      profilePhotoUrl: null,
      isProfilePhotoChanged: false,
      isFeatureAnnouncementModuleAllowed: false,
      isFeatureScheduledJobsAllowed: false,
      isFeatureTotalStatisticsAllowed: false,
      isFeatureAccessRequestAllowed: false
    },
    created: function(): void {
      const _userProxyApi: UserProxyApi = createApi(UserProxyApi)
      this.isBusyAuthenticating = true

      authHelper.onAuthenticate = async (user): Promise<void> => {
        const userLanguageCode = await _userProxyApi.getUserLanguage(user.profile.user_id)
        i18n.locale = getUserCultureFromCookie(userLanguageCode)
        moment.locale(i18n.locale)
        Vue.prototype.$userInfo = user.profile
        window['userInfo'] = user.profile
        window['envName'] = ClientConfiguration.clientConfig.ENV
        this.userInfo = user.profile

        this.isAuthenticated = true

        this.isBusyAuthenticating = false

        // Feature Modules
        if (!Globals.CompanyFeatureModulesInfo.companyFeatureModules) {
          const clarificationTextApi: ClarificationTextApi = createApi(ClarificationTextApi)

          Globals.CompanyFeatureModulesInfo = await Globals.getCompanyFeatureModulesInfo(_userProxyApi)

          this.isFeatureModuleEval360Allowed = await Globals.checkIfFeatureModuleAllowed(
            Globals.FeatureModuleEval360Code,
            this.userInfo.CompanyEmployeeId
          )
          Globals.isFeatureModuleEval360Allowed = this.isFeatureModuleEval360Allowed

          this.isFeatureModuleOkrAllowed = await Globals.checkIfFeatureModuleAllowed(
            Globals.FeatureModulOkrCode,
            this.userInfo.CompanyEmployeeId
          )
          Globals.isFeatureModuleOkrAllowed = this.isFeatureModuleOkrAllowed

          this.isFeatureAnnouncementModuleAllowed = await Globals.checkIfFeatureModuleAllowed(
            Globals.FeatureModuleAnnouncementCode
          )
          Globals.isFeatureAnnouncementModuleAllowed = this.isFeatureAnnouncementModuleAllowed

          this.isFeatureScheduledJobsAllowed = await Globals.checkIfFeatureModuleAllowed(
            Globals.FeatureModuleScheduledJobsCode
          )
          Globals.isFeatureScheduledJobsAllowed = this.isFeatureScheduledJobsAllowed

          this.isFeatureTotalStatisticsAllowed = await Globals.checkIfFeatureModuleAllowed(
            Globals.FeatureModuleTotalStatisticsCode
          )

          Globals.isFeatureTotalStatisticsAllowed = this.isFeatureTotalStatisticsAllowed

          this.isFeatureAccessRequestAllowed = await Globals.checkIfFeatureModuleAllowed(
            Globals.FeatureModuleAccessRequestCode
          )

          Globals.isFeatureAccessRequestAllowed = this.isFeatureAccessRequestAllowed
        }

        // When using Vue Buefy, it enables datepicker to show month and day with defaultMonthNames and defaultDayNames.
        // Since default cannot always be changed, it has been added to use in child components
        this.localeMonthNames = moment.months()

        // moment.weekdays() gives full name of the days
        // Using the full name of the days takes up too much space on the screen and breaks the UI
        switch (i18n.locale) {
          case 'en':
            this.localeDayNames = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
            Localizer.setLanguage('en', VueMultiSelectEnData.data)
            break
          case 'tr':
            this.localeDayNames = ['Pa', 'Pt', 'S', 'Ç', 'Pe', 'C', 'Ct']
            Localizer.setLanguage('tr', VueMultiSelectTrData.data)
            break
          case 'es':
            this.localeDayNames = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
            Localizer.setLanguage('es', VueMultiSelectEsData.data)
            break
          case 'fr':
            this.localeDayNames = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
            Localizer.setLanguage('fr', VueMultiSelectFrData.data)
            break
          default:
            this.localeDayNames = moment.weekdays()
        }

        // End Handle Localization Of User

        // Translation Key-Value Tester
        // console.log(Globals.i18n.t('shared.pleaseWait'))
      }
      AuthHelper.onAuthorize = async (): Promise<void> => {
        this.canAccessAdmin = AuthHelper.hasAdminAccess
      }

      const url = window.location.href.split('#')
      let path = '/'
      if (url.length > 1) {
        path = url[1].split('?')[0]
      }
      handleAdminPanel(this, path, this.$router['options'].routes)
    },
    mounted: function(): void {
      handleVueAxiosInterceptors(this)

      //eğer kullanıcı daha önce onayladıysa tekrar gözükemeyecek
    },
    methods: {
      setLocale: async function(lang): Promise<void> {
        i18n.locale = lang
        setUserCultureOnCookie(lang)

        // delete cached sidebar-menu info from cache
        Globals.clearStorageItem(btoa(Globals.SidebarMenuInfoCacheKey))
        // Change User Language On UserApi
        const userProxyApi: UserProxyApi = createApi(UserProxyApi)

        await userProxyApi.updateUserCultureForClient(i18n.locale)
        document.location.reload()
      },
      saveProfilePhoto: async function(): Promise<void> {
        this.isBusy = true
        try {
          const documentType: DocumentListMultipleResponseDocumentTypeEnum = 'ThanxProfileDocument'
          const documentManager = new DocumentManagementService()
          const croppedImage = await this.profilePhotoUploadingModal.promisedBlob('image/png', 1)

          if (croppedImage != null) {
            const fileName = 'profile-photo.png'
            await documentManager.uploadBlob(documentType, this.userInfo.CompanyEmployeeId, croppedImage, fileName)
          }

          this.isBusy = false
          Notiflix.Notify.merge({ timeout: toastSuccessDurationInMs })
          Notiflix.Notify.success(this.$t('shared.success') as string)
          this.isProfilePhotoChanged = true
        } catch (error) {
          Notiflix.Notify.merge({ timeout: toastDurationInMs, closeButton: true })
          Notiflix.Notify.failure(this.$t('shared.error') as string)
          handleNotifyClose(toastDurationInMs)
          console.log(error)
        }
      },
      checkClarification: async function(): Promise<void> {
        const clarificationTextApi = createApi(ClarificationTextApi)
        const result = await clarificationTextApi.checkApprovalStatus()

        if (result == false) this.clarificationTextPopoverModalActive = true
        else this.clarificationTextPopoverModalActive = false
      },
      checkTermsOfUse: async function(): Promise<void> {
        const termsOfUseApi = createApi(TermsOfUseApi)
        const result = await termsOfUseApi.checkApprovalStatus()
        //if it is not approved, modal is active
        if (result == false) this.termsOfUsePopoverModalActive = true
        else this.termsOfUsePopoverModalActive = false
      },
      approveClarification: async function(): Promise<void> {
        //aprove metodu eklenecek clarification text tablosunda isaproveneeded true mu false mu bakılacak
        const clarificationTextApi = createApi(ClarificationTextApi)
        const result = await clarificationTextApi.approve()
        this.clarificationTextPopoverModalActive = false
      },
      approveTermsOfUse: async function(termsOfUseId): Promise<void> {
        const termsOfUseApi = createApi(TermsOfUseApi)
        const result = await termsOfUseApi.approve()
        this.termsOfUsePopoverModalActive = false
      },

      handleEmployeeProfilePhoto: async function(): Promise<void> {
        const employeeApi = createApi(EmployeeApi)
        const employeeInfo = await employeeApi.getEmployeeBaseInfo(this.userInfo.CompanyEmployeeId)
        if (employeeInfo.imageFileName) {
          this.profilePhotoUrl = Globals.getDocumentShowByFileNameUrl(employeeInfo.imageFileName)
        }
      }
    },
    computed: {
      isBusy: {
        get: function(): boolean {
          return this.isBusyCount > 0
        },
        set: function(val: boolean): void {
          if (val === true) {
            this.isBusyCount++
          } else {
            this.isBusyCount--
          }
        }
      }
    },
    watch: {
      isBusy: function(val): void {
        if (val && !Globals.getIsLoadingDisabled()) {
          Notiflix.Loading.custom(Globals.i18n.t('shared.pleaseWait'))
        } else {
          Notiflix.Loading.remove()
        }
      },
      isProfilePhotoChanged: function(val): void {
        if (val === true) {
          document.location.reload()
        }
      },
      isBusyAuthenticating: function(val): void {
        if (val) {
          Notiflix.Loading.custom(Globals.i18n.t('shared.pleaseWait'))
        } else {
          this.handleEmployeeProfilePhoto()
          this.checkClarification()
          this.checkTermsOfUse()
          Notiflix.Loading.remove()
        }
      }
    }
  })
}

init()

import {
  CompanySettingParameterApi,
  ConfigApi,
  EmployeeApi,
  EmployeeLightDto,
  PerformanceFormOperationYearApi,
  YearParameter,
  YearSelectOption
} from '../metadata/hrnext-performance-service-api'
import { GetFeatureToggle, emptyGuid, handleNotifyClose } from './helper'
import {
  momentDefaultDateFormat,
  momentDefaultDateTimeFormat,
  toastDurationInMs,
  toastSuccessDurationInMs
} from './constants'

import { DialogProgrammatic as Dialog } from 'buefy'
import { Globals } from './globals'
import { LinqService } from 'linquest'
import Notiflix from 'notiflix'
import { UserInfo } from './userInfo'
import { Vue } from 'vue-property-decorator'
import { createApi } from '.'
import moment from 'moment'

interface TranslationDtoBase {
  languageCountryCode?: string
}

export abstract class BaseComponent extends Vue {
  constructor() {
    super()
    this.configApi = createApi(ConfigApi)
    this.userInfo = (Vue.prototype.$userInfo as UserInfo) ?? window['userInfo']
    this.watchUserInfo(() => {
      this.companyId = this.userInfo.CompanyId
      this.currentUserId = this.userInfo.user_id
    })
  }

  userInfo: UserInfo
  employeeInfo: EmployeeLightDto
  companyId: string
  currentUserId: string
  securityPrefix = ''
  configApi: ConfigApi

  // DirtyCheck Params Start
  isDirty = false
  byPassDirtyCheck = false
  // DirtyCheck Params End

  // years selection
  isFlexibleYear: boolean
  yearOptions: Array<YearSelectOption> = []
  activeYearParams?: YearParameter = Globals.getActiveYearParams()
  selectedYearOption = this.activeYearParams?.yearId ?? this.activeYearParams?.year?.toString() ?? ''
  defaultYearOption = Globals.getDefaultYearOption()

  async created(useYears = false, useDefaultYear = false): Promise<void> {
    if (useDefaultYear) this.selectedYearOption = this.defaultYearOption

    if (!this.userInfo) {
      const userInfo = await this.watchUserInfoAsync()
      this.userInfo = userInfo
    }
    await this.handleEmployee()

    this.isFlexibleYear = await this.HasFlexibleYear()

    await this.loadYearOptions()

    if (useYears) {
      await this.setDefaultYearParams()
      if (useDefaultYear) {
        this.selectedYearOption = await this.getDefaultYearOption()
        this.activeYearParams = this.getSelectedYearParams()
      }
    }
  }

  watchUserInfo = (fn?) => {
    const check = () => {
      const user = this.userInfo ?? window['userInfo']
      if (user && user.user_id) {
        this.userInfo = user
        return user
      } else return undefined
    }
    if (check() && fn) fn(this.userInfo)
    else {
      const timer = setInterval(() => {
        if (check()) {
          if (fn) fn(this.userInfo)
          clearInterval(timer)
        }
      }, 300)
    }
  }

  async watchUserInfoAsync(): Promise<UserInfo> {
    return new Promise(resolve => {
      this.watchUserInfo(userInfo => resolve(userInfo))
    })
  }

  async handleEmployee(): Promise<void> {
    const employeeApi = createApi(EmployeeApi)
    this.employeeInfo = await Globals.getEmployeeBaseInfo(employeeApi)
  }

  async isFeatureEnabled(toggle): Promise<boolean> {
    return await GetFeatureToggle(toggle, this.configApi)
  }

  getUrlParameter(name: string): string {
    const result = this.$route.query[name]
    return Array.isArray(result) ? (result.length > 0 ? result[0] : '') : (result as string)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  navigate(path: string, params?: { [key: string]: any }, completed?: Function, aborted?: Function): void {
    const s = []
    if (params) {
      for (const p in params) {
        s.push(`${p}=${params[p]}`)
      }

      path += '?' + s.join('&')
    }
    this.$router.push(path, completed, aborted)
    window.scrollTo(0, 0)
  }

  async loadYearOptions(): Promise<void> {
    if (!this.hasCompanyId) return

    if (this.isFlexibleYear) {
      const performanceFormOperationYearApi = createApi(PerformanceFormOperationYearApi)
      const years = await performanceFormOperationYearApi.listTranslated().toArrayAsync()
      this.yearOptions = years.map(v => {
        return { value: v.id, label: v.name, startDate: v.startDate, endDate: v.endDate }
      })
      this.yearOptions = this.yearOptions.sort((a, b) => (a.startDate > b.startDate ? 1 : -1))
    }

    if (this.yearOptions.length == 0) {
      for (let year = 2019; year <= new Date().getFullYear() + 3; year++) {
        this.yearOptions.push({
          value: year.toString(),
          label: year.toString(),
          startDate: new Date(year, 0, 1),
          endDate: new Date(year, 11, 31)
        })
      }
    }
  }

  updateCurrentYear(): void {
    if (!this.selectedYearOption) return

    this.activeYearParams = this.getSelectedYearParams()
    Globals.setActiveYearParams(this.activeYearParams)
  }

  async getDefaultYearOption(): Promise<string> {
    if (this.defaultYearOption) return Promise.resolve(this.defaultYearOption)
    if (!this.hasCompanyId) return Promise.resolve('')

    const companySettingParameterApi = createApi(CompanySettingParameterApi)
    const settings = await companySettingParameterApi.parameterList().toArrayAsync()
    const parameters = settings.filter(x => x.companyId == this.userInfo.CompanyId)

    this.defaultYearOption = new Date().getFullYear().toString()

    if (parameters && parameters.length > 0) {
      this.defaultYearOption = this.isFlexibleYear
        ? parameters[0].performanceFormOperationYearId
        : parameters[0].parameterValue.toString()
    }

    Globals.setDefaultYearOption(this.defaultYearOption)

    return Promise.resolve(this.defaultYearOption)
  }

  async setDefaultYearParams(): Promise<void> {
    if (this.activeYearParams || !this.hasCompanyId) return

    this.selectedYearOption = await this.getDefaultYearOption()

    if (this.selectedYearOption) {
      this.updateCurrentYear()
    }
  }

  getSelectedYearParams(option = ''): YearParameter {
    if (!this.selectedYearOption && !option) return

    if (!option) option = this.selectedYearOption

    const activeYearParams: YearParameter = {
      year: -1,
      yearId: null
    }

    if (option.toString().length == 4) {
      activeYearParams.year = parseInt(option)
    } else {
      activeYearParams.yearId = option
    }

    if (this.yearOptions && this.yearOptions.length > 0) {
      const yearOption = this.yearOptions.find(m => m.value == option)
      activeYearParams.startDate = yearOption?.startDate
      activeYearParams.endDate = yearOption?.endDate
    }

    if (activeYearParams.yearId && activeYearParams.yearId != emptyGuid() && activeYearParams.endDate) {
      activeYearParams.year = activeYearParams.endDate.getFullYear()
    }

    return activeYearParams
  }

  getYearLabel(year?: number, yearId?: string): string {
    if (!year && !yearId) return ''

    if (this.isFlexibleYear && (!yearId || yearId == emptyGuid())) return year ? year.toString() : ''

    if (this.isFlexibleYear && this.yearOptions && this.yearOptions.length > 0) {
      const yearOption = this.yearOptions.find(m => m.value == yearId)
      if (yearOption && yearOption.label) return yearOption.label
    }

    return year ? year.toString() : ''
  }

  getYearObjLabel(yearParams?: YearParameter): string {
    if (!yearParams) return ''
    return this.getYearLabel(yearParams.year, yearParams.yearId)
  }

  fillYearParams(year?: number, yearId?: string): YearParameter {
    const yearParams: YearParameter = {
      year: year ?? -1,
      yearId: yearId ?? null
    }

    if (!year && !yearId) return yearParams

    if (this.yearOptions && this.yearOptions.length > 0) {
      const option = this.findYearOption(year, yearId)
      const yearOption = this.yearOptions.find(m => m.value == option)
      yearParams.startDate = yearOption?.startDate
      yearParams.endDate = yearOption?.endDate
    }

    if (yearParams.yearId && yearParams.yearId != emptyGuid() && yearParams.endDate && yearParams.year <= 0) {
      yearParams.year = yearParams.endDate.getFullYear()
    }

    return yearParams
  }

  findYearOption(year?: number, yearId?: string): string {
    if (!year && !yearId) return ''

    return (this.isFlexibleYear ? yearId : year?.toString()) ?? ''
  }

  getYearParams(year?: number, yearId?: string): YearParameter {
    const yearParams = this.fillYearParams(year, yearId)
    if (!this.isFlexibleYear) yearParams.yearId = null
    return yearParams
  }

  HasFlexibleYear = async (): Promise<boolean> => {
    if (Globals.getFlexYearTest() != undefined) {
      return Globals.getFlexYearTest()
    }
    const isFlexibleYear = await this.isFeatureEnabled(Globals.IsOperationYearSystemActive)

    return isFlexibleYear
  }

  hasCompanyId = (): boolean => (this.userInfo && this.userInfo.CompanyId ? true : false)

  // ARŞİV HACK: Notify ederken callback verildiğinde "tıkla ve kapat" özelliği aktif oluyor 🤦🏻‍♂️
  // `closeButton: true` da verebilirdik. O zaman ise otomatik kapanma özelliği gidiyor 🤦🏻‍♂️🤦🏻‍♂️
  // O yüzden (success hariç - KE) tüm Notiflix methodlarına `() => null` şeklinde bir callback verdik
  // YENİ HACK: `() => null` ihtiyacımızı çözmediği için, closeButton'ın işlevini javascript kullanarak bir süre sonra tetikliyoruz.

  // Success İşlemi için closeButton gereği yok, daha kısa süre (toastSuccessDurationInMs) gösterim yeterli.
  public toastSuccess(message: string, durationInMs?: number): void {
    Notiflix.Notify.merge({ timeout: durationInMs ?? toastSuccessDurationInMs })
    Notiflix.Notify.success(message)
    handleNotifyClose(durationInMs ?? toastSuccessDurationInMs)
  }

  public toastInformation(message: string, durationInMs?: number): void {
    Notiflix.Notify.merge({ timeout: durationInMs ?? toastDurationInMs, closeButton: true })
    Notiflix.Notify.info(message)
    handleNotifyClose(durationInMs ?? toastDurationInMs)
  }

  public toastError(message: string, durationInMs?: number): void {
    Notiflix.Notify.merge({ timeout: durationInMs ?? toastDurationInMs, closeButton: true })
    Notiflix.Notify.failure(message)
    handleNotifyClose(durationInMs ?? toastDurationInMs)
  }

  public toastWarning(message: string, durationInMs?: number): void {
    Notiflix.Notify.merge({ timeout: durationInMs ?? toastDurationInMs, closeButton: true })
    Notiflix.Notify.warning(message)
    handleNotifyClose(durationInMs ?? toastDurationInMs)
  }

  getTranslationForCurrentUserLanguage<T extends TranslationDtoBase>(translations: Array<T>): T {
    return this.getTranslationWithLanguageCountryCode(translations, Globals.i18n.locale)
  }

  getTranslationWithLanguageCountryCode<T extends TranslationDtoBase>(
    translations: Array<T>,
    languageCountryCode: string
  ): T {
    for (const translation of translations) {
      if (translation.languageCountryCode === languageCountryCode) {
        return translation
      }
    }

    // Fallback language
    const fallBackLocale = Globals.i18n.fallbackLocale.toString()
    if (languageCountryCode !== fallBackLocale)
      return this.getTranslationWithLanguageCountryCode(translations, fallBackLocale)

    return { languageCountryCode: languageCountryCode } as T
  }

  /**
   * Ekranda gösterilen tarih formatlarının tutarlı olması için merkezi bir yere ekledim
   * İleride belki user language'a göre gösteririz
   * */
  formatDateForDisplay(date: Date, format?: string): string {
    if (!date) return null

    const usedFormat = format ? format : momentDefaultDateFormat
    const formattedDate = moment(date)
      .local()
      .format(usedFormat)

    const result = formattedDate ? formattedDate : '-'
    return result
  }

  /**
   * Ekranda gösterilen tarih formatlarının tutarlı olması için merkezi bir yere ekledim
   * İleride belki user language'a göre gösteririz
   * */
  formatDateTimeForDisplay(date: Date, format?: string): string {
    if (!date) return null

    const usedFormat = format ? format : momentDefaultDateTimeFormat
    return this.formatDateForDisplay(date, usedFormat)
  }

  // canSee(key: string) {
  //     return getSecurityProvider().canSee(this.securityPrefix + key);
  // }

  // canInteract(key: string) {
  //     return getSecurityProvider().canInteract(this.securityPrefix + key);
  // }

  watchDtoForDirtyCheck(dtoName): void {
    this.$watch(
      dtoName,
      () => {
        this.isDirty = true
      },
      {
        deep: true
      }
    )
  }
  beforeRouteLeave(to, from, next): void {
    this.checkDirty(next)
  }

  checkDirty(next): void {
    if (this.isDirty && !this.byPassDirtyCheck) {
      this.showDirtyDialog(next)
    } else {
      next()
    }
  }
  showDirtyDialog(next): void {
    Dialog.confirm({
      title: this.$t('shared.warning') as string,
      message: this.$t('common.baseComponent.continueWithoutSaving') as string,
      confirmText: this.$t('shared.continue') as string,
      cancelText: this.$t('shared.cancel') as string,
      type: 'is-danger',
      hasIcon: true,
      onConfirm: async () => next(),
      onCancel: async () => next(false)
    })
  }

  sortName(a, b): number {
    const aname = a.name
    const bname = b.name
    const alphabet = 'AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz0123456789'
    if (aname.length === 0 || bname.length === 0) {
      return aname.length - bname.length
    }
    for (let i = 0; i < aname.length && i < bname.length; i++) {
      const ai = alphabet.indexOf(aname[i])
      const bi = alphabet.indexOf(bname[i])
      if (ai !== bi) {
        return ai - bi
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private readonly propNameGetterProxy = new Proxy({} as any, {
    get: (_, prop): string | number | symbol => prop
  })

  /**
   * Verilen nesne içerisnde reaktif olarak tanımlı olmayan bir prop'u
   * Vuejs'in haberi olacak şekilde günceller
   * @param item Değiştirilecek nesne (Örn: task)
   * @param selector Değişecek prop'un lambda formatında yazımı (Örn: task => task.isSaving)
   * @param value Prop'un yeni değeri
   */
  setValueReactive<T extends object, K>(item: T, selector: (item: T) => K, value: K): void {
    const propName = (selector(this.propNameGetterProxy) as unknown) as string // "task => task.isSaving" için "isSaving" buluyoruz
    this.$set(item, propName, value)
  }
}

export class BaseEntityComponent<TEntity, TService> extends BaseComponent {
  constructor(protected entityType: new () => TEntity, protected serviceType: new () => TService) {
    super()

    this.service = this.createService()
    this.entityTypeName = this.getFuncName(entityType)
  }

  service: TService = {} as TService
  protected entityTypeName: string

  createService(): TService {
    return new this.serviceType()
  }

  // canSee(key: string) {
  //     key = key.replace('entity.', this.entityTypeName + '.');
  //     return super.canSee(key);
  // }

  // canInteract(key: string) {
  //     key = key.replace('entity.', this.entityTypeName + '.');
  //     return super.canInteract(key);
  // }

  getFuncName(func): string {
    const funcNameRegex = /function (.*?)[\s|(]|class (.*?)\s|\{/
    const results = funcNameRegex.exec(func.toString())

    return results[1] || results[2]
  }
}

export class BaseEntityEditComponent<TEntity, TService> extends BaseEntityComponent<TEntity, TService> {
  entity: TEntity = {} as TEntity
  id

  initialize(): void {
    this.id = this.getUrlParameter('id')
    // if (id)
    //     this.getEntity(id);
    // else
    //     this.createEntity();
  }

  // getEntity(id: number) {
  //     let p = this.service.GetAsync(id);
  //     return p.then(e => {
  //         this.entity = e;
  //         this.entityReady();
  //         return p;
  //     }, error => {
  //         // this.handleError(error);
  //         return p;
  //     });
  // }

  // createEntity() {
  //     let entity = new this.entityType();
  //     this.entity = entity;
  //     this.entityReady();
  // }
}

export class BaseEntityListComponent<TEntity, TService extends LinqService> extends BaseEntityComponent<
  TEntity,
  TService
> {
  editEntity(entity: TEntity): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const id = (entity as any).id
    const path = this.$router.currentRoute.path
    this.navigate(`${path}/edit?id=${id}`)
  }
}

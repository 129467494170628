import { Component, Vue } from 'vue-property-decorator'
import './navigation.scss'

@Component({
  template: require('./navigation.pug'),
  props: ['userInfo', 'canAccessAdmin']
})
export class NavigationComponent extends Vue {
  constructor() {
    super()
  }
  canAccessAdmin
  userInfo

  toggleHamburger(): void {
    const burger = document.querySelector('.burger')
    const nav = document.querySelector('#main-navbar')

    burger.classList.toggle('is-active')
    nav.classList.toggle('is-active')
  }
}

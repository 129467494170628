/**
 * HRNext Performance WebApi
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import {
  IntegrationDataFilterDto,
  IntegrationInfoDto,
  IntegrationSelectDto,
  StagingDataDto
} from './models';
import { LinqService, QueryOptions } from 'linquest';

import { IAjaxProvider } from 'jinqu';

/**
 * ScheduledJobApi - API class
 */
export class IntegrationDataApi extends LinqService {

  /**
   * Creates a new IntegrationDataApi class.
   *
   * @param ajaxProvider The Linquest Ajax Provider to inject. When not specified, default Fetch provider will be used.
   */
  constructor(ajaxProvider?: IAjaxProvider) {
    super("/performance-api", ajaxProvider);
  }

  /**
   * @param request 
   */
  getIntegrationHistory(request?: IntegrationDataFilterDto, options?: QueryOptions) {
    // create query
    const url = `/Integration/GetIntegrationHistory`;
    let query = this.createQuery<StagingDataDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = request;
    query = query.withOptions(options);

    return query.toArrayAsync();
  }

  /**
   */
  getIntegrationInfo(options?: QueryOptions) {
    // create query
    const url = `/Integration/GetIntegrationInfo`;
    let query = this.createQuery<IntegrationInfoDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    query = query.withOptions(options);

    return query.firstOrDefaultAsync();
  }

  /**
 */
  downloadIntegrationData(id: string, options?: any) {
    // create query
    const url = `/Integration/DownloadIntegrationData`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};
    options.responseType = 'blob';

    // set query parameters
    options.params.push({ key: 'Id', value: id });
    query = query.withOptions(options);

    return query.firstOrDefaultAsync();
  }

}

/**
 * HRNext Performance WebApi
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { IAjaxProvider } from 'jinqu';
import { LinqService, QueryOptions } from 'linquest';
import {
  CrossCompanyManagerDto,
} from './models';

/**
 * CrossCompanyManagerApi - API class
 */
export class CrossCompanyManagerApi extends LinqService {

  /**
   * Creates a new CrossCompanyManagerApi class.
   *
   * @param ajaxProvider The Linquest Ajax Provider to inject. When not specified, default Fetch provider will be used.
   */
  constructor(ajaxProvider?: IAjaxProvider) {
    super("/performance-api", ajaxProvider);
  }

  /**
   * @param dto 
   */
  addOrUpdateAsync(dto?: CrossCompanyManagerDto, options?: QueryOptions) {
    // create query
    const url = `/CrossCompanyManager/AddOrUpdateAsync`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = dto;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param id 
   */
  deleteAsync(id?: string, options?: QueryOptions) {
    // create query
    const url = `/CrossCompanyManager/DeleteAsync`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param id 
   */
  getAsync(id?: string, options?: QueryOptions) {
    // create query
    const url = `/CrossCompanyManager/GetAsync`;
    let query = this.createQuery<CrossCompanyManagerDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param id 
   */
  getTranslatedAsync(id?: string, options?: QueryOptions) {
    // create query
    const url = `/CrossCompanyManager/GetTranslatedAsync`;
    let query = this.createQuery<CrossCompanyManagerDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   */
  list(options?: QueryOptions) {
    // create query
    const url = `/CrossCompanyManager/List`;
    let query = this.createQuery<CrossCompanyManagerDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    query = query.withOptions(options);

    return query;
  }

  /**
   */
  listTranslated(options?: QueryOptions) {
    // create query
    const url = `/CrossCompanyManager/ListTranslated`;
    let query = this.createQuery<CrossCompanyManagerDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    query = query.withOptions(options);

    return query;
  }

  /**
   * @param dto 
   */
  saveAsync(dto?: CrossCompanyManagerDto, options?: QueryOptions) {
    // create query
    const url = `/CrossCompanyManager/SaveAsync`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = dto;
    query = query.withOptions(options);

    return query.firstAsync();
  }
}

/**
 * HRNext Performance WebApi
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { IAjaxProvider } from 'jinqu'
import { LinqService, QueryOptions } from 'linquest'
import {
  OkrTeamEmployeeListBulkRequestDto,
  OkrTeamEmployeeBulkRequestDto,
  OkrTeamEmployeeDto,
  OkrTeamEmployeeQueryDto,
  OkrGetTeamEmployeeDto
} from './models'

/**
 * OkrTeamEmployeeApi - API class
 */
export class OkrTeamEmployeeApi extends LinqService {
  /**
   * Creates a new OkrTeamEmployeeApi class.
   *
   * @param ajaxProvider The Linquest Ajax Provider to inject. When not specified, default Fetch provider will be used.
   */
  constructor(ajaxProvider?: IAjaxProvider) {
    super("/performance-api", ajaxProvider)
  }

  /**
   * @param dto
   */
  addOrUpdateAsync(dto?: OkrTeamEmployeeDto, options?: QueryOptions) {
    // create query
    const url = `/OkrTeamEmployee/AddOrUpdateAsync`
    let query = this.createQuery<any>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'POST'
    options.params = options.params || []
    options.headers = options.headers || {}

    // set body parameter
    options.data = dto
    query = query.withOptions(options)

    return query.firstAsync()
  }

  /**
   * @param teamEmployeeBulkRequestDto
   */
  addOrUpdateBulk(teamEmployeeBulkRequestDto?: OkrTeamEmployeeBulkRequestDto, options?: QueryOptions) {
    // create query
    const url = `/OkrTeamEmployee/AddOrUpdateBulk`
    let query = this.createQuery<any>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'POST'
    options.params = options.params || []
    options.headers = options.headers || {}

    // set body parameter
    options.data = teamEmployeeBulkRequestDto
    query = query.withOptions(options)

    return query.firstAsync()
  }

  /**
   * @param id
   */
  deleteAsync(id?: string, options?: QueryOptions) {
    // create query
    const url = `/OkrTeamEmployee/DeleteAsync`
    let query = this.createQuery<any>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'GET'
    options.params = options.params || []
    options.headers = options.headers || {}

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() })
    query = query.withOptions(options)

    return query.firstAsync()
  }

  /**
   * @param id
   */
  getAsync(id?: string, options?: QueryOptions) {
    // create query
    const url = `/OkrTeamEmployee/GetAsync`
    let query = this.createQuery<OkrTeamEmployeeDto>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'GET'
    options.params = options.params || []
    options.headers = options.headers || {}

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() })
    query = query.withOptions(options)

    return query.firstAsync()
  }

  /**
   * @param okrKeyResultId
   */
  getSavedEmployeeOfKeyResultAsync(okrKeyResultId?: string, options?: QueryOptions) {
    // create query
    const url = `/OkrTeamEmployee/GetSavedEmployeeOfKeyResultAsync`
    let query = this.createQuery<OkrGetTeamEmployeeDto>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'GET'
    options.params = options.params || []
    options.headers = options.headers || {}

    // set query parameters
    options.params.push({ key: 'okrKeyResultId', value: okrKeyResultId && okrKeyResultId.toString() })
    query = query.withOptions(options)

    return query
  }

  /**
   * @param teamEmployeeListBulkRequestDto
   */
  getTeamEmployeeBulk(teamEmployeeListBulkRequestDto?: OkrTeamEmployeeListBulkRequestDto, options?: QueryOptions) {
    // create query
    const url = `/OkrTeamEmployee/GetTeamEmployeeBulk`
    let query = this.createQuery<OkrTeamEmployeeDto>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'POST'
    options.params = options.params || []
    options.headers = options.headers || {}

    // set body parameter
    options.data = teamEmployeeListBulkRequestDto
    query = query.withOptions(options)

    return query
  }

  /**
   * @param teamId
   */
  getTeamListOfEmployeeByTeamId(teamId?: string, options?: QueryOptions) {
    // create query
    const url = `/OkrTeamEmployee/GetTeamListOfEmployeeByTeamId`
    let query = this.createQuery<OkrGetTeamEmployeeDto>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'GET'
    options.params = options.params || []
    options.headers = options.headers || {}

    // set query parameters
    options.params.push({ key: 'teamId', value: teamId && teamId.toString() })
    query = query.withOptions(options)

    return query
  }

  /**
   * @param id
   */
  getTranslatedAsync(id?: string, options?: QueryOptions) {
    // create query
    const url = `/OkrTeamEmployee/GetTranslatedAsync`
    let query = this.createQuery<OkrTeamEmployeeDto>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'GET'
    options.params = options.params || []
    options.headers = options.headers || {}

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() })
    query = query.withOptions(options)

    return query.firstAsync()
  }

  /**
   */
  list(options?: QueryOptions) {
    // create query
    const url = `/OkrTeamEmployee/List`
    let query = this.createQuery<OkrTeamEmployeeDto>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'GET'
    options.params = options.params || []
    options.headers = options.headers || {}

    query = query.withOptions(options)

    return query
  }

  /**
   */
  listTranslated(options?: QueryOptions) {
    // create query
    const url = `/OkrTeamEmployee/ListTranslated`
    let query = this.createQuery<OkrTeamEmployeeDto>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'GET'
    options.params = options.params || []
    options.headers = options.headers || {}

    query = query.withOptions(options)

    return query
  }

  /**
   * @param teamEmployeeBulkRequestDto
   */
  removeTeamEmployeesBulk(teamEmployeeBulkRequestDto?: OkrTeamEmployeeBulkRequestDto, options?: QueryOptions) {
    // create query
    const url = `/OkrTeamEmployee/RemoveTeamEmployeesBulk`
    let query = this.createQuery<any>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'POST'
    options.params = options.params || []
    options.headers = options.headers || {}

    // set body parameter
    options.data = teamEmployeeBulkRequestDto
    query = query.withOptions(options)

    return query.firstAsync()
  }

  /**
   * @param dto
   */
  saveAsync(dto?: OkrTeamEmployeeDto, options?: QueryOptions) {
    // create query
    const url = `/OkrTeamEmployee/SaveAsync`
    let query = this.createQuery<any>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'POST'
    options.params = options.params || []
    options.headers = options.headers || {}

    // set body parameter
    options.data = dto
    query = query.withOptions(options)

    return query.firstAsync()
  }

  /**
   * @param employeeIds
   */
  searchAsync(employeeIds?: Array<string>, options?: QueryOptions) {
    // create query
    const url = `/OkrTeamEmployee/SearchAsync`
    let query = this.createQuery<OkrTeamEmployeeQueryDto>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'POST'
    options.params = options.params || []
    options.headers = options.headers || {}

    // set body parameter
    options.data = employeeIds
    query = query.withOptions(options)

    return query
  }
}

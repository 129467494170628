/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { AuthHelper } from './auth-helper'
import { QueryOptions } from 'linquest'
import { UserProxyApi } from '../metadata/hrnext-performance-service-api'
import { createApi } from '.'

export class UserService {
  private userProxyApi: UserProxyApi

  constructor() {
    this.userProxyApi = createApi(UserProxyApi)
  }

  GetCurrentUserSecurityInfo(options?: QueryOptions) {
    const that = this
    return new Promise(function(resolve, reject) {
      try {
        that.userProxyApi.getCurrentUserPermissionInfoForClient(options).then(res => {
          resolve(res)
        })
      } catch (error) {
        reject(error)
      }
    })
  }

  GetCurrentUserPageSecurityInfo() {
    return new Promise(function(resolve) {
      const key = btoa(AuthHelper.usersSecurityItemsKey)
      const usersSecurityItemsJson = sessionStorage.getItem(key)
      if (!usersSecurityItemsJson) {
        resolve([])
      }
      const usersSecurityItems = JSON.parse(decodeURIComponent(escape(window.atob(usersSecurityItemsJson))))
      resolve(usersSecurityItems)
    })
  }

  RefreshApiCacheItemsOfUser() {
    const that = this
    return new Promise(function(resolve, reject) {
      try {
        that.userProxyApi.clearUserCache().then(res => {
          resolve(res)
        })
      } catch (error) {
        reject(error)
      }
    })
  }
}

/**
 * HRNext Performance WebApi
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { IAjaxProvider } from 'jinqu';
import { LinqService, QueryOptions } from 'linquest';
import {
  OkrMyEquipObjectivesWithDetailInfoViewModel,
  OkrObjectivesInManager,
  StrategyBasedObjectives,
  StrategyObjectivesRequest,
  OkrObjectiveDto,
  OkrObjectivesWithDetail,
  OkrObjectiveWithKeyResultAndEmployeeSummaryViewModel,
  OkrObjectivesWithDetailInfoViewModel,
} from './models';

/**
 * OkrObjectiveApi - API class
 */
export class OkrObjectiveApi extends LinqService {

  /**
   * Creates a new OkrObjectiveApi class.
   *
   * @param ajaxProvider The Linquest Ajax Provider to inject. When not specified, default Fetch provider will be used.
   */
  constructor(ajaxProvider?: IAjaxProvider) {
    super("/performance-api", ajaxProvider);
  }

  /**
   * @param dto 
   */
  addOrUpdateAsync(dto?: OkrObjectiveDto, options?: QueryOptions) {
    // create query
    const url = `/OkrObjective/AddOrUpdateAsync`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = dto;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param model 
   */
  addOrUpdateNested(model?: OkrObjectiveDto, options?: QueryOptions) {
    // create query
    const url = `/OkrObjective/AddOrUpdateNested`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = model;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param id 
   */
  deleteAsync(id?: string, options?: QueryOptions) {
    // create query
    const url = `/OkrObjective/DeleteAsync`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param id 
   */
  getAsync(id?: string, options?: QueryOptions) {
    // create query
    const url = `/OkrObjective/GetAsync`;
    let query = this.createQuery<OkrObjectiveDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   */
  getMyEquipCreatedObjectivesWithDetailInfo(okrPeriodIds?: Array<string>,options?: QueryOptions) {
    // create query
    const url = `/OkrObjective/GetMyEquipCreatedObjectivesWithDetailInfo`;
    let query = this.createQuery<OkrMyEquipObjectivesWithDetailInfoViewModel>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    options.data = okrPeriodIds
    query = query.withOptions(options);

    return query;
  }

  /**
   */
  getMyEquipObjectivesWithDetailInfo(okrPeriodIds?: Array<string>,options?: QueryOptions) {
    // create query
    const url = `/OkrObjective/GetMyEquipObjectivesWithDetailInfo`;
    let query = this.createQuery<OkrMyEquipObjectivesWithDetailInfoViewModel>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    options.data = okrPeriodIds
    query = query.withOptions(options);

    return query;
  }

  /**
   * @param objectiveId 
   * @param showTeamKrs 
   */
  getObjective(objectiveId?: string, showTeamKrs?: boolean, options?: QueryOptions) {
    // create query
    const url = `/OkrObjective/GetObjective`;
    let query = this.createQuery<OkrObjectiveDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'ObjectiveId', value: objectiveId && objectiveId.toString() });
    // set query parameters
    options.params.push({ key: 'ShowTeamKrs', value: showTeamKrs && showTeamKrs.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   */
  getObjectiveAndKeyResultForManager(okrPeriodIds?: Array<string>, options?: QueryOptions) {
    // create query
    const url = `/OkrObjective/GetObjectiveAndKeyResultForManager`;
    let query = this.createQuery<OkrObjectivesInManager>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    options.data = okrPeriodIds
    query = query.withOptions(options);

    return query;
  }

  /**
   * @param objectiveId 
   * @param showTeamOkr 
   */
  getObjectiveAndKeyResultWithEmployee(objectiveId?: string, showTeamOkr?: boolean, options?: QueryOptions) {
    // create query
    const url = `/OkrObjective/GetObjectiveAndKeyResultWithEmployee`;
    let query = this.createQuery<OkrObjectiveWithKeyResultAndEmployeeSummaryViewModel>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'objectiveId', value: objectiveId && objectiveId.toString() });
    // set query parameters
    options.params.push({ key: 'showTeamOkr', value: showTeamOkr && showTeamOkr.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   */
  getObjectiveWithDetailCreatedByCurrentUser(options?: QueryOptions) {
    // create query
    const url = `/OkrObjective/GetObjectiveWithDetailCreatedByCurrentUser`;
    let query = this.createQuery<OkrObjectivesWithDetail>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    query = query.withOptions(options);

    return query.firstAsync();
  }

  getMyPersonalObjectives(okrPeriodIds?: Array<string>,options?: QueryOptions) {
    const url = `/OkrObjective/GetMyPersonalObjectives`;
    let query = this.createQuery<OkrObjectivesWithDetailInfoViewModel>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'okrPeriodIds', value: okrPeriodIds && okrPeriodIds.toString() });
    query = query.withOptions(options);

    return query;
  }

  /**
   * @param id 
   */
  getTranslatedAsync(id?: string, options?: QueryOptions) {
    // create query
    const url = `/OkrObjective/GetTranslatedAsync`;
    let query = this.createQuery<OkrObjectiveDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   */
  list(options?: QueryOptions) {
    // create query
    const url = `/OkrObjective/List`;
    let query = this.createQuery<OkrObjectiveDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    query = query.withOptions(options);

    return query;
  }

  /**
   */
  listTranslated(options?: QueryOptions) {
    // create query
    const url = `/OkrObjective/ListTranslated`;
    let query = this.createQuery<OkrObjectiveDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    query = query.withOptions(options);

    return query;
  }

  /**
   * @param dto 
   */
  saveAsync(dto?: OkrObjectiveDto, options?: QueryOptions) {
    // create query
    const url = `/OkrObjective/SaveAsync`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = dto;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param request 
   */
  strategyBasedObjectives(request?: StrategyObjectivesRequest, options?: QueryOptions) {
    // create query
    const url = `/OkrObjective/StrategyBasedObjectives`;
    let query = this.createQuery<StrategyBasedObjectives>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = request;
    query = query.withOptions(options);

    return query;
  }

  /**
   * @param objectiveId 
   */
  updateObjectiveProgressScore(objectiveId?: string, options?: QueryOptions) {
    // create query
    const url = `/OkrObjective/UpdateObjectiveProgressScore`;
    let query = this.createQuery<boolean>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'objectiveId', value: objectiveId && objectiveId.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }
}

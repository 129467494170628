/**
 * HRNext Performance WebApi
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import {
  CompanyFeatureModuleInfoViewModel,
  CreateTicketRequest,
  CreateTicketResponse,
  TicketCategoryTranslatedDto,
  UserDto,
  UserGroupDto,
  UserGroupViewModel,
  UserPermissionInfoViewModel,
  UserSearchResultDto,
} from './models';
import { LinqService, QueryOptions } from 'linquest';

import { IAjaxProvider } from 'jinqu';

/**
 * UserProxyApi - API class
 */
export class UserProxyApi extends LinqService {

  /**
   * Creates a new UserProxyApi class.
   *
   * @param ajaxProvider The Linquest Ajax Provider to inject. When not specified, default Fetch provider will be used.
   */
  constructor(ajaxProvider?: IAjaxProvider) {
    super("/performance-api", ajaxProvider);
  }

  /**
   * @param userGroupDto 
   */
  addOrUpdateUserGroupForClient(userGroupDto?: UserGroupDto, options?: QueryOptions) {
    // create query
    const url = `/UserProxy/AddOrUpdateUserGroupForClient`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = userGroupDto;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   */
  clearUserCache(options?: QueryOptions) {
    // create query
    const url = `/UserProxy/ClearUserCache`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param createTicketRequest 
   */
  createTicket(createTicketRequest?: CreateTicketRequest, options?: QueryOptions) {
    // create query
    const url = `/UserProxy/CreateTicket`;
    let query = this.createQuery<CreateTicketResponse>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = createTicketRequest;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   */
  getCurrentCompanyFeatureModules(options?: QueryOptions) {
    // create query
    const url = `/UserProxy/GetCurrentCompanyFeatureModules`;
    let query = this.createQuery<CompanyFeatureModuleInfoViewModel>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    query = query.withOptions(options);

    return query.firstAsync();
  }

  

  getModifiedUserNames(userIds?: string, options?: QueryOptions) {
    // create query
    const url = `/UserProxy/GetModifiedUserNames`
    let query = this.createQuery<UserDto>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'GET'
    options.params = options.params || []
    options.headers = options.headers || {}

    // set body parameter
    options.params.push({ key: 'userIds', value: userIds && userIds.toString() });

    query = query.withOptions(options);

    return query
  }



  /**
   */
  getCurrentUserPermissionInfoForClient(options?: QueryOptions) {
    // create query
    const url = `/UserProxy/GetCurrentUserPermissionInfoForClient`;
    let query = this.createQuery<UserPermissionInfoViewModel>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   */
  listEditableUserGroupsForCompanyForClient(options?: QueryOptions) {
    // create query
    const url = `/UserProxy/ListEditableUserGroupsForCompanyForClient`;
    let query = this.createQuery<UserGroupViewModel>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param parentTicketCategoryId 
   */
  listTicketCategoryTranslated(parentTicketCategoryId?: string, options?: QueryOptions) {
    // create query
    const url = `/UserProxy/ListTicketCategoryTranslated`;
    let query = this.createQuery<TicketCategoryTranslatedDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'parentTicketCategoryId', value: parentTicketCategoryId && parentTicketCategoryId.toString() });
    query = query.withOptions(options);

    return query;
  }

  /**
   * @param userId 
   * @param groupId 
   */
  removeUserFromGroup(userId?: string, groupId?: string, options?: QueryOptions) {
    // create query
    const url = `/UserProxy/RemoveUserFromGroup`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'userId', value: userId && userId.toString() });
    // set query parameters
    options.params.push({ key: 'groupId', value: groupId && groupId.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param searchStr 
   * @param pageNo 
   * @param pageSize 
   */
  search(searchStr?: string, pageNo?: number, pageSize?: number, options?: QueryOptions) {
    // create query
    const url = `/UserProxy/Search`;
    let query = this.createQuery<UserSearchResultDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'searchStr', value: searchStr && searchStr.toString() });
    // set query parameters
    options.params.push({ key: 'pageNo', value: pageNo && pageNo.toString() });
    // set query parameters
    options.params.push({ key: 'pageSize', value: pageSize && pageSize.toString() });
    query = query.withOptions(options);

    return query;
  }

  /**
   * @param searchStr 
   * @param pageNo 
   * @param pageSize 
   */
  getUserLanguage(employeeId?: string, options?: QueryOptions) {
    // create query
    const url = `/UserProxy/GetUserLanguage`;
    let query = this.createQuery<UserDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'userId', value: employeeId && employeeId.toString() });
    // set query parameters
   // options.params.push({ key: 'pageNo', value: pageNo && pageNo.toString() });
    // set query parameters
    //options.params.push({ key: 'pageSize', value: pageSize && pageSize.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param userCultureCode 
   */
  updateUserCultureForClient(userCultureCode?: string, options?: QueryOptions) {
    // create query
    const url = `/UserProxy/UpdateUserCultureForClient`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'userCultureCode', value: userCultureCode && userCultureCode.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }
}

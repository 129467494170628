/* eslint-disable @typescript-eslint/no-explicit-any */
//import { SecurityNetItem, PermissionLevel, SecurityNetProvider, setSecurityProvider } from './security'

import * as Oidc from 'oidc-client'

import Bowser from 'bowser'
import { Globals } from '../globals'

export interface UserManagerSettings {
  authority: string
  client_id: string
  response_type?: string
  scope?: string
  redirect_uri?: string
  post_logout_redirect_uri?: string
  popup_post_logout_redirect_uri?: string
  prompt?: string
  display?: string
  max_age?: number
  ui_locales?: string
  acr_values?: string
  filterProtocolClaims?: boolean
}

export interface AppSettings {
  applicationName: string
  setSecurityItems: boolean
  setUserRoles: boolean
  byPassRoutePermissionCheck: boolean
  basePath: string
  securityItemsSourceName: string
}

export class AuthHelper {
  static oidcConfig: UserManagerSettings
  static appSettings: AppSettings
  static userManager: Oidc.UserManager = new Oidc.UserManager(AuthHelper.oidcConfig)
  static authToken: string
  static isHeaderTokenSet: boolean
  static userService: any
  static hasAdminAccess = false

  constructor(settings: UserManagerSettings, appSettings: AppSettings, userService: any) {
    AuthHelper.oidcConfig = settings
    AuthHelper.appSettings = appSettings
    AuthHelper.userManager = new Oidc.UserManager(AuthHelper.oidcConfig)
    AuthHelper.userService = userService
  }

  onAuthenticate: (userInfo) => void
  static onAuthorize: () => void

  beforeEach(router, to, from, next): void {
    const loginCallback = to.query['login_callback'] === 'true'
    const logoutCallback = to.query['logout_callback'] === 'true'

    const p = this.checkAuth(router, loginCallback, logoutCallback)
    p.then(res => {
      if (res) {
        if (AuthHelper.appSettings.setSecurityItems === true) {
          const pr = AuthHelper.setSecurityItemsOfUserAndCheck(router, to)
          pr.then(p => {
            if (p) {
              AuthHelper.createEvent('usersSecurityItemsIsSet')
              next()
            }
          })
          pr.catch(() => {
            next(false)
          })
        } else {
          AuthHelper.createEvent('usersSecurityItemsIsSet')
          next()
        }
      } else {
        next(false)
      }
    })

    p.catch(() => {
      next(false)
    })
  }

  checkAuth(router: any, loginCallback: boolean, logoutCallback: boolean): Promise<any> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this
    return new Promise((resolve, reject): void => {
      if (loginCallback) {
        AuthHelper.userManager
          .signinRedirectCallback()
          .then(function() {
            AuthHelper.userManager.getUser().then(function(user) {
              if (user !== null && user !== undefined) {
                const returnUrl = AuthHelper.getAndClearReturnUrl()
                const browser = Bowser.getParser(window.navigator.userAgent)
                const browserName = browser.getBrowserName()
                if (returnUrl) {
                  if (browserName === 'Chrome' || returnUrl !== '/#/') {
                    window.location.href = returnUrl
                    resolve(false)
                    return
                  }
                }
                window.location.reload()
                resolve(false)
                return
              }
            })
            resolve(false)
          })
          .catch(function(e) {
            console.error(e)
            reject(e)
            if (e != null && e.toString().indexOf('No matching state') > -1) {
              window.location.href = window.location.href.split('#')[0]
            }
          })
      } else if (logoutCallback) {
        AuthHelper.removeLocalSessionItemsOfUser()
        const url = window.location.href.split('#')[0]
        window.location.href = url
      } else {
        AuthHelper.userManager.getUser().then(function(user) {
          if (!user) {
            // user not authenticated
            AuthHelper.setReturnUrl()
            AuthHelper.userManager.signinRedirect()
            resolve(false)
          } else {
            // user authenticated
            if (self.onAuthenticate) self.onAuthenticate(user)
            if (!AuthHelper.isHeaderTokenSet) {
              AuthHelper.authToken = user.access_token
              AuthHelper.setAuthHeader()
              AuthHelper.isHeaderTokenSet = true
              if (AuthHelper.appSettings.setUserRoles) {
                AuthHelper.setUserRoles()
              }
            }
            resolve(true)
          }
        })
      }
    })
  }

  static get usersRolesKey(): string {
    return 'usersRoles' + '-' + this.appSettings.applicationName
  }

  static get usersSecurityItemsKey(): string {
    return 'usersSecurityItems' + '-' + this.appSettings.applicationName
  }

  static get companyFeatureModulesKey(): string {
    return 'companyFeatureModules' + '-' + this.appSettings.applicationName
  }

  static createEvent(eventName, eventData?): void {
    const event = new CustomEvent(eventName, { detail: eventData })
    document.dispatchEvent(event)
  }

  static navigate(
    router: any,
    path: string,
    params?: { [key: string]: any },
    completed?: Function,
    aborted?: Function
  ): void {
    const s = []
    if (params) {
      for (const p in params) {
        s.push(`${p}=${params[p]}`)
      }

      path += '?' + s.join('&')
    }
    router.push(path, completed, aborted)
  }

  static checkIfValidRoute(router, path): boolean {
    const routes = router.app.$router['options'].routes
    return routes.asQueryable().any(x => x.path === path)
  }

  static setSecurityItemsOfUserAndCheck(router, to): Promise<any> {
    return new Promise((resolve): void => {
      if (to.path === '/unauthorized' || to.path === '/404' || to.path === '/ui-kit') {
        resolve(true)
        return
      }

      if (!AuthHelper.checkIfValidRoute(router, to.path)) {
        this.navigate(router, '/404')
        resolve(false)
        return
      }

      const usersSecurityItemsJson = sessionStorage.getItem(btoa(this.usersSecurityItemsKey))
      if (!usersSecurityItemsJson) {
        const p = AuthHelper.userService.GetCurrentUserSecurityInfo()
        p.then(
          r => {
            if (r) {
              sessionStorage.setItem(
                btoa(this.usersSecurityItemsKey),
                btoa(unescape(encodeURIComponent(JSON.stringify(r))))
              )
              const isAllowed = this.checkRoutePermission(to, r)
              if (!this.appSettings.byPassRoutePermissionCheck && !isAllowed) {
                this.navigate(router, '/unauthorized')
                resolve(false)
              } else {
                resolve(true)
                // TODO: Upcoming feature
                // this.setPagePermissions(resolve, to)
              }
            } else {
              this.navigate(router, '/unauthorized')
              resolve(false)
            }
          },
          error => {
            AuthHelper.createEvent('authHelperGenericError', error.detail)
            resolve(false)
          }
        )
      } else {
        const isAllowed = this.checkRoutePermission(
          to,
          JSON.parse(decodeURIComponent(escape(window.atob(usersSecurityItemsJson))))
        )
        if (!this.appSettings.byPassRoutePermissionCheck && !isAllowed) {
          this.navigate(router, '/unauthorized')
          resolve(false)
        } else {
          resolve(true)
          // TODO: Upcoming feature
          // this.setPagePermissions(resolve, to)
        }
      }
    })
  }

  static checkRoutePermission(to, usersPermissionInfo: any): boolean {
    AuthHelper.hasAdminAccess = usersPermissionInfo.permissionItems
      .asQueryable()
      .any(x => x.value === '/admin' && x.source === this.appSettings.securityItemsSourceName)

    if (this.onAuthorize) this.onAuthorize()

    return AuthHelper.checkPermission(to.path, usersPermissionInfo)
  }

  static checkPermission(path, usersPermissionInfo: any): boolean {
    let allowed = false
    for (let i = 0; i < usersPermissionInfo.permissionItems.length; i++) {
      const x = usersPermissionInfo.permissionItems[i]
      if (x.value === path && x.type === 2 && x.source === Globals.SecurityItemsSourceName) {
        allowed = true
        break
      }
    }
    return allowed
  }

  static setPagePermissions(resolve, to): void {
    // const p = AuthHelper.userService.GetCurrentUserPageSecurityInfo(to.path)
    // p.then(
    //   r => {
    //     if (r) {
    //       setSecurityProvider(new SecurityNetProvider(r))
    //       resolve(true)
    //     }
    //   },
    //   error => {
    //     AuthHelper.createEvent('authHelperGenericError', error.detail)
    //     resolve(false)
    //   }
    // )
  }

  static removeLocalSessionItemsOfUser(): void {
    const list = Object.keys(sessionStorage)
    for (let i = 0; i < list.length; i++) {
      if (list[i].indexOf('oidc.user:') != 0) {
        sessionStorage.removeItem(list[i])
      }
    }
  }

  static refreshApiCacheItemsOfUser(): Promise<any> {
    return AuthHelper.userService.RefreshApiCacheItemsOfUser()
  }

  static setUserRoles(): Promise<any> {
    return new Promise((resolve): void => {
      const userRolesJson = sessionStorage.getItem(btoa(this.usersRolesKey))
      if (userRolesJson) {
        resolve(JSON.parse(decodeURIComponent(escape(window.atob(userRolesJson)))))
      } else {
        const p = AuthHelper.userService.GetCurrentUserRoleInfo()
        p.then(
          r => {
            sessionStorage.setItem(btoa(this.usersRolesKey), btoa(unescape(encodeURIComponent(JSON.stringify(r)))))
            resolve(r)
          },
          error => {
            AuthHelper.createEvent('authHelperGenericError', error.detail)
            resolve(null)
          }
        )
      }
    })
  }

  static getUsersSecurityItemsFromCache(): any {
    const usersSecurityItemsJson = sessionStorage.getItem(btoa(this.usersSecurityItemsKey))
    if (!usersSecurityItemsJson) {
      return null
    }
    return JSON.parse(decodeURIComponent(escape(window.atob(usersSecurityItemsJson))))
  }

  static setReturnUrl(): void {
    let url = window.location.href
    if (url.indexOf('#') > 0) {
      url = url.split('#')[1]
      url = '/#' + url
      if (AuthHelper.appSettings.basePath && AuthHelper.appSettings.basePath != '/') {
        let basePath = AuthHelper.appSettings.basePath
        basePath = basePath.replace(/\/+$/g, '')
        url = basePath + url
      }
    }

    const returnUrl = sessionStorage.getItem('returnUrl')
    if (!returnUrl) sessionStorage.setItem('returnUrl', url)
  }

  static getAndClearReturnUrl(): string {
    const returnUrl = sessionStorage.getItem('returnUrl')
    if (returnUrl) {
      sessionStorage.removeItem('returnUrl')
    }
    return returnUrl
  }

  static setAuthHeader(): void {
    const o = XMLHttpRequest.prototype.open
    XMLHttpRequest.prototype.open = function(...args): any {
      const res = o.apply(this, args)
      this.setRequestHeader('Authorization', 'bearer ' + AuthHelper.authToken)

      const rsc = this.onreadystatechange
      if (rsc) {
        this.onreadystatechange = function(...args): any {
          if (this.readyState === 4 && this.status === 401) {
            AuthHelper.userManager.signinRedirect()
          }
          if (this.readyState === 4 && this.status === 403) {
            const url = this.responseURL.split('?')[0]
            AuthHelper.createEvent('authHelper403Error', { url })
          }
          return rsc.apply(this, args)
        }
      } else {
        this.onreadystatechange = function(): void {
          if (this.readyState === 4 && this.status === 401) {
            AuthHelper.userManager.signinRedirect()
          }
          if (this.readyState === 4 && this.status === 403) {
            const url = this.responseURL.split('?')[0]
            AuthHelper.createEvent('authHelper403Error', { url })
          }
        }
      }
      return res
    }
  }
}

/**
 * HRNext DocumentManagement WebApi
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { IAjaxProvider } from 'jinqu';
import { LinqService, QueryOptions } from 'linquest';
import {
  DocumentListMultipleRequest,
  DocumentListMultipleResponse,
  DocumentDeleteRequest,
  DocumentListResponse,
  DocumentGetResponse,
  DocumentSaveRequest,
} from './models';

/**
 * DocumentApi - API class
 */
export class DocumentApi extends LinqService {

  /**
   * Creates a new DocumentApi class.
   *
   * @param ajaxProvider The Linquest Ajax Provider to inject. When not specified, default Fetch provider will be used.
   */
  constructor(ajaxProvider?: IAjaxProvider) {
    super("/document-management-api", ajaxProvider);
  }

  /**
   * @param request 
   */
  _delete(request?: DocumentDeleteRequest, options?: QueryOptions) {
    // create query
    const url = `/Document/Delete`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'DELETE';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = request;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param fileName 
   * @param documentType 
   */
  documentDeleteRequestByFileName(fileName?: string, documentType?: 'Undefined' | 'CommentDocument' | 'TaskDocument' | 'BadgeImageDocument' | 'ProductCategoryImageDocument' | 'ProductImageDocument' | 'AnnouncementImageDocument' | 'ThanxProfileDocument' | 'CompanySettingDocument' | 'AnnouncementDocument' | 'CompanyInfoDocument', options?: QueryOptions) {
    // create query
    const url = `/Document/DocumentDeleteRequestByFileName`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'DELETE';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'fileName', value: fileName && fileName.toString() });
    // set query parameters
    options.params.push({ key: 'documentType', value: documentType && documentType.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param documentMetadataId 
   */
  get(documentMetadataId?: string, options?: QueryOptions) {
    // create query
    const url = `/Document/Get`;
    let query = this.createQuery<DocumentGetResponse>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'DocumentMetadataId', value: documentMetadataId && documentMetadataId.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param documentType 
   * @param referenceId 
   */
  list(documentType?: 'Undefined' | 'CommentDocument' | 'TaskDocument' | 'BadgeImageDocument' | 'ProductCategoryImageDocument' | 'ProductImageDocument' | 'AnnouncementImageDocument' | 'ThanxProfileDocument' | 'CompanySettingDocument' | 'AnnouncementDocument' | 'CompanyInfoDocument', referenceId?: string, options?: QueryOptions) {
    // create query
    const url = `/Document/List`;
    let query = this.createQuery<DocumentListResponse>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'DocumentType', value: documentType && documentType.toString() });
    // set query parameters
    options.params.push({ key: 'ReferenceId', value: referenceId && referenceId.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param request 
   */
  listMultiple(request?: DocumentListMultipleRequest, options?: QueryOptions) {
    // create query
    const url = `/Document/ListMultiple`;
    let query = this.createQuery<DocumentListMultipleResponse>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = request;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param request 
   */
  save(request?: DocumentSaveRequest, options?: QueryOptions) {
    // create query
    const url = `/Document/Save`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = request;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param documentType 
   * @param referenceId 
   */
  show(documentType?: 'Undefined' | 'CommentDocument' | 'TaskDocument' | 'BadgeImageDocument' | 'ProductCategoryImageDocument' | 'ProductImageDocument' | 'AnnouncementImageDocument' | 'ThanxProfileDocument' | 'CompanySettingDocument' | 'AnnouncementDocument' | 'CompanyInfoDocument', referenceId?: string, options?: QueryOptions) {
    // create query
    const url = `/Document/Show`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'DocumentType', value: documentType && documentType.toString() });
    // set query parameters
    options.params.push({ key: 'ReferenceId', value: referenceId && referenceId.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param fileName 
   */
  showByFileName(fileName?: string, options?: QueryOptions) {
    // create query
    const url = `/Document/ShowByFileName`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'fileName', value: fileName && fileName.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }
}

import './terms-of-use-popover.scss'

import { Component, Vue } from 'vue-property-decorator'
import {
  TermsOfUseDto,
  TermsOfUseTranslatedDto,
  TermsOfUseTranslationDto
} from '../../metadata/hrnext-performance-service-api/models'

import { TermsOfUseApi } from '../../metadata/hrnext-performance-service-api/TermsOfUseApi'
import { createApi } from '../../common'

@Component({
  template: require('./terms-of-use-popover.pug')
})
export class TermsOfUsePopoverComponent extends Vue {
  termsOfUseApi: TermsOfUseApi
  termsOfUSeTranslatedDTo: TermsOfUseTranslatedDto = {}
  constructor() {
    super()
    this.termsOfUseApi = createApi(TermsOfUseApi)
  }
  async created(): Promise<void> {
    const translated = await this.termsOfUseApi
      .listTranslated()
      .where(x => x.isActive == true && x.isApproveNeeded == true)
      .toArrayAsync()
    this.termsOfUSeTranslatedDTo = await translated.asQueryable().firstOrDefaultAsync()
  }
}

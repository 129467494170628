export const handleEvents = (): void => {
  // AuthHelper Events
  document.addEventListener(
    'authHelperGenericError',
    function(e) {
      // TODO
      const errorDetail = e['detail']
      console.log(errorDetail)
      alert(errorDetail)
    },
    false
  )

  document.addEventListener(
    'authHelper403Error',
    function(e) {
      // TODO
      const errorDetail = e['detail']
      console.log(errorDetail)
      alert(errorDetail)
    },
    false
  )
}

import '@trevoreyre/autocomplete-vue/dist/style.css'
import './search-box.scss'

import { Component, Vue } from 'vue-property-decorator'
import { EmployeeApi, EmployeeLightQueryDto } from '../../../metadata/hrnext-performance-service-api'

import Autocomplete from '@trevoreyre/autocomplete-vue'
import { Globals } from '../../../common/globals'
import { createApi } from '../../../common'

@Component({
  template: require('./search-box.pug'),
  components: {
    Autocomplete
  }
})
export class SearchBoxComponent extends Vue {
  employeeApi: EmployeeApi
  resultList: Array<EmployeeLightQueryDto> = []

  $refs!: {
    searchbox: HTMLFormElement
  }

  async created(): Promise<void> {
    this.employeeApi = createApi(EmployeeApi)
  }

  async search(input): Promise<EmployeeLightQueryDto[]> {
    if (input.length < 3) {
      return []
    }
    try {
      Globals.setIsLoadingDisabled(true)
      this.resultList = await this.employeeApi.quickSearch(input).toArrayAsync()
      return this.resultList
    } catch (error) {
      return []
    } finally {
      Globals.setIsLoadingDisabled(false)
    }
  }

  getResultValue(result): string {
    return result.middleName
      ? result.name + ' ' + result.middleName + ' ' + result.surname
      : result.name + ' ' + result.surname
  }

  handleSubmit(result): void {
    // TODO: Tech Debt
    // Bu componenti Vue değil de BaseComponent'ten türettiğimizde
    // this.userInfo bilgisi dolmadığındna konsola çeşitli hatalar yazdırıyor
    // Bir race condition olduğunu düşünüyorum ama sorunu detaylı inceleyemedim
    // O yüzden base'deki navigate yerine $router.push methodu ile ilerledik
    // this.navigate(`../profile?employeeId=${result.id}`)
    this.$refs.searchbox.value = ''
    this.$router.push(`../profile?employeeId=${result.id}`)
  }

  onFocus(event): void {
    this.$nextTick(() => {
      if (event.target) {
        const target = event.target as Element
        target.classList.add('active')
      }
    })
  }

  onBlur(event): void {
    this.$nextTick(() => {
      if (event.target) {
        const target = event.target as Element
        target.classList.remove('active')
      }
    })
  }
}

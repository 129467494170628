/**
 * HRNext Performance WebApi
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import {
  EmployeeDto,
  EmployeeLightDto,
  EmployeeLightQueryDto,
  EmployeeNameDto,
  EmployeePerformanceViewModel,
  EmployeeSearchDto,
  EmployeeSearchResultDto,
  LeftEmployeeDto,
  LockedEmployeeDto,
  TeamOfManagerDto,
  YearParameter
} from './models'
import { LinqService, QueryOptions } from 'linquest'

import { IAjaxProvider } from 'jinqu'

/**
 * EmployeeApi - API class
 */
export class EmployeeApi extends LinqService {
  /**
   * Creates a new EmployeeApi class.
   *
   * @param ajaxProvider The Linquest Ajax Provider to inject. When not specified, default Fetch provider will be used.
   */
  constructor(ajaxProvider?: IAjaxProvider) {
    super("/performance-api", ajaxProvider)
  }

  /**
   * @param dto
   */
  addOrUpdateAsync(dto?: EmployeeDto, options?: QueryOptions) {
    // create query
    const url = `/Employee/AddOrUpdateAsync`
    let query = this.createQuery<any>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'POST'
    options.params = options.params || []
    options.headers = options.headers || {}

    // set body parameter
    options.data = dto
    query = query.withOptions(options)

    return query.firstAsync()
  }

  /**
   * @param employeeId
   */
  checkIfUserAuthorizedForEmployee(employeeId?: string, options?: QueryOptions) {
    // create query
    const url = `/Employee/CheckIfUserAuthorizedForEmployee`
    let query = this.createQuery<boolean>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'GET'
    options.params = options.params || []
    options.headers = options.headers || {}

    // set query parameters
    options.params.push({ key: 'employeeId', value: employeeId && employeeId.toString() })
    query = query.withOptions(options)

    return query.firstAsync()
  }

  /**
   * @param id
   */
  deleteAsync(id?: string, options?: QueryOptions) {
    // create query
    const url = `/Employee/DeleteAsync`
    let query = this.createQuery<any>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'GET'
    options.params = options.params || []
    options.headers = options.headers || {}

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() })
    query = query.withOptions(options)

    return query.firstAsync()
  }

  /**
   * @param id
   */
  getAsync(id?: string, options?: QueryOptions) {
    // create query
    const url = `/Employee/GetAsync`
    let query = this.createQuery<EmployeeDto>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'GET'
    options.params = options.params || []
    options.headers = options.headers || {}

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() })
    query = query.withOptions(options)

    return query.firstAsync()
  }

  /**
   */
  getCurrentEmployeeBaseInfo(options?: QueryOptions) {
    // create query
    const url = `/Employee/GetCurrentEmployeeBaseInfo`
    let query = this.createQuery<EmployeeLightDto>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'GET'
    options.params = options.params || []
    options.headers = options.headers || {}

    query = query.withOptions(options)

    return query.firstAsync()
  }

  /**
   */
  getCurrentEmployeeQuickInfo(options?: QueryOptions) {
    // create query
    const url = `/Employee/GetCurrentEmployeeQuickInfo`
    let query = this.createQuery<EmployeeLightQueryDto>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'GET'
    options.params = options.params || []
    options.headers = options.headers || {}

    query = query.withOptions(options)

    return query.firstAsync()
  }

  /**
   * @param employeeId
   */
  getEmployeeBaseInfo(employeeId?: string, options?: QueryOptions) {
    // create query
    const url = `/Employee/GetEmployeeBaseInfo`
    let query = this.createQuery<EmployeeLightDto>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'GET'
    options.params = options.params || []
    options.headers = options.headers || {}

    // set query parameters
    options.params.push({ key: 'employeeId', value: employeeId && employeeId.toString() })
    query = query.withOptions(options)

    return query.firstAsync()
  }

  /**
   * @param employeeIdSet
   */
  getEmployeesById(employeeIdSet?: Array<string>, options?: QueryOptions) {
    // create query
    const url = `/Employee/GetEmployeesById`
    let query = this.createQuery<EmployeeNameDto>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'POST'
    options.params = options.params || []
    options.headers = options.headers || {}

    // set body parameter
    options.data = employeeIdSet
    query = query.withOptions(options)

    return query
  }

  /**
   * @param yearParams
   */
  getTeamMemberPerformanceSummary(yearParams?: YearParameter, options?: QueryOptions) {
    // create query
    const url = `/Employee/GetTeamMemberPerformanceSummary`
    let query = this.createQuery<EmployeePerformanceViewModel>(url)

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = yearParams;
    query = query.withOptions(options);

    return query
  }

  /**
   * @param employeeId
   */
  getTeamMembersOfManagerEmployee(employeeId?: string, options?: QueryOptions) {
    // create query
    const url = `/Employee/GetTeamMembersOfManagerEmployee`
    let query = this.createQuery<EmployeeLightDto>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'GET'
    options.params = options.params || []
    options.headers = options.headers || {}

    // set query parameters
    options.params.push({ key: 'employeeId', value: employeeId && employeeId.toString() })
    query = query.withOptions(options)

    return query
  }

  /**
   * @param employeeId
   */
  getTeamMembersOfManagerEmployeeWithManager(employeeId?: string, options?: QueryOptions) {
    // create query
    const url = `/Employee/GetTeamMembersOfManagerEmployeeWithManager`
    let query = this.createQuery<EmployeeLightDto>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'GET'
    options.params = options.params || []
    options.headers = options.headers || {}

    // set query parameters
    options.params.push({ key: 'employeeId', value: employeeId && employeeId.toString() })
    query = query.withOptions(options)

    return query
  }

  /**
   * @param emploeeId
   * @param includeSubMembers
   */
  getTeamOfManager(emploeeId?: string, includeSubMembers?: boolean, options?: QueryOptions) {
    // create query
    const url = `/Employee/GetTeamOfManager`
    let query = this.createQuery<TeamOfManagerDto>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'GET'
    options.params = options.params || []
    options.headers = options.headers || {}

    // set query parameters
    options.params.push({ key: 'emploeeId', value: emploeeId && emploeeId.toString() })
    // set query parameters
    options.params.push({ key: 'includeSubMembers', value: includeSubMembers && includeSubMembers.toString() })
    query = query.withOptions(options)

    return query
  }

  /**
   * @param id
   */
  getTranslatedAsync(id?: string, options?: QueryOptions) {
    // create query
    const url = `/Employee/GetTranslatedAsync`
    let query = this.createQuery<EmployeeDto>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'GET'
    options.params = options.params || []
    options.headers = options.headers || {}

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() })
    query = query.withOptions(options)

    return query.firstAsync()
  }

  /**
   */
  list(options?: QueryOptions) {
    // create query
    const url = `/Employee/List`
    let query = this.createQuery<EmployeeDto>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'GET'
    options.params = options.params || []
    options.headers = options.headers || {}

    query = query.withOptions(options)

    return query
  }

  /**
   * @param startingDate
   * @param endingDate
   */
  listLeftEmployees(startingDate?: Date, endingDate?: Date, options?: QueryOptions) {
    // create query
    const url = `/Employee/ListLeftEmployees`
    let query = this.createQuery<LeftEmployeeDto>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'GET'
    options.params = options.params || []
    options.headers = options.headers || {}

    // set query parameters
    options.params.push({ key: 'startingDate', value: startingDate && startingDate.toString() })
    // set query parameters
    options.params.push({ key: 'endingDate', value: endingDate && endingDate.toString() })
    query = query.withOptions(options)

    return query
  }

  /**
   */
  listLight(options?: QueryOptions) {
    // create query
    const url = `/Employee/ListLight`
    let query = this.createQuery<EmployeeLightDto>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'GET'
    options.params = options.params || []
    options.headers = options.headers || {}

    query = query.withOptions(options)

    return query
  }

  /**
   */
  listTranslated(options?: QueryOptions) {
    // create query
    const url = `/Employee/ListTranslated`
    let query = this.createQuery<EmployeeDto>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'GET'
    options.params = options.params || []
    options.headers = options.headers || {}

    query = query.withOptions(options)

    return query
  }

  /**
   */
  lockedEmployees(options?: QueryOptions) {
    // create query
    const url = `/Employee/LockedEmployees`
    let query = this.createQuery<LockedEmployeeDto>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'GET'
    options.params = options.params || []
    options.headers = options.headers || {}

    query = query.withOptions(options)

    return query
  }

  /**
   * @param employeeId
   */
  quickGet(employeeId?: string, options?: QueryOptions) {
    // create query
    const url = `/Employee/QuickGet`
    let query = this.createQuery<EmployeeLightQueryDto>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'GET'
    options.params = options.params || []
    options.headers = options.headers || {}

    // set query parameters
    options.params.push({ key: 'employeeId', value: employeeId && employeeId.toString() })
    query = query.withOptions(options)

    return query.firstAsync()
  }

  /**
   * @param employeeIds
   */
  quickList(employeeIds?: Array<string>, options?: QueryOptions) {
    // create query
    const url = `/Employee/QuickList`
    let query = this.createQuery<EmployeeLightQueryDto>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'POST'
    options.params = options.params || []
    options.headers = options.headers || {}

    // set body parameter
    options.data = employeeIds
    query = query.withOptions(options)

    return query
  }

  /**
   * @param companyId
   */
  quickListAllEmployees(companyId?: string, options?: QueryOptions) {
    // create query
    const url = `/Employee/QuickListAllEmployees`
    let query = this.createQuery<EmployeeLightQueryDto>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'GET'
    options.params = options.params || []
    options.headers = options.headers || {}

    // set query parameters
    options.params.push({ key: 'companyId', value: companyId && companyId.toString() })
    query = query.withOptions(options)

    return query
  }

  /**
   * @param fullName
   */
  quickSearch(fullName?: string, options?: QueryOptions) {
    // create query
    const url = `/Employee/QuickSearch`
    let query = this.createQuery<EmployeeLightQueryDto>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'GET'
    options.params = options.params || []
    options.headers = options.headers || {}

    // set query parameters
    options.params.push({ key: 'fullName', value: fullName && fullName.toString() })
    query = query.withOptions(options)

    return query
  }

  /**
   * @param dto
   */
  saveAsync(dto?: EmployeeDto, options?: QueryOptions) {
    // create query
    const url = `/Employee/SaveAsync`
    let query = this.createQuery<any>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'POST'
    options.params = options.params || []
    options.headers = options.headers || {}

    // set body parameter
    options.data = dto
    query = query.withOptions(options)

    return query.firstAsync()
  }

  /**
   * @param employeeSearchDto
   */
  searchAsync(employeeSearchDto?: EmployeeSearchDto, options?: QueryOptions) {
    // create query
    const url = `/Employee/SearchAsync`
    let query = this.createQuery<EmployeeSearchResultDto>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'POST'
    options.params = options.params || []
    options.headers = options.headers || {}

    // set body parameter
    options.data = employeeSearchDto
    query = query.withOptions(options)

    return query
  }

  /**
   * @param userName
   */
  unlockEmployee(userName?: string, options?: QueryOptions) {
    // create query
    const url = `/Employee/UnlockEmployee`
    let query = this.createQuery<boolean>(url)

    // initialize options
    options = options || {}
    options.method = options.method || 'POST'
    options.params = options.params || []
    options.headers = options.headers || {}

    // set body parameter
    options.data = userName
    query = query.withOptions(options)

    return query.firstAsync()
  }
}

/**
 * HRNext Performance WebApi
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { IAjaxProvider } from 'jinqu';
import { LinqService, QueryOptions } from 'linquest';
import {
  EvaluationQuestionTypeDto,
} from './models';

/**
 * EvaluationQuestionTypeApi - API class
 */
export class EvaluationQuestionTypeApi extends LinqService {

  /**
   * Creates a new EvaluationQuestionTypeApi class.
   *
   * @param ajaxProvider The Linquest Ajax Provider to inject. When not specified, default Fetch provider will be used.
   */
  constructor(ajaxProvider?: IAjaxProvider) {
    super("/performance-api", ajaxProvider);
  }

  /**
   * @param id 
   */
  getAsync(id?: 0 | 1 | 2, options?: QueryOptions) {
    // create query
    const url = `/EvaluationQuestionType/GetAsync`;
    let query = this.createQuery<EvaluationQuestionTypeDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param id 
   */
  getTranslatedAsync(id?: 0 | 1 | 2, options?: QueryOptions) {
    // create query
    const url = `/EvaluationQuestionType/GetTranslatedAsync`;
    let query = this.createQuery<EvaluationQuestionTypeDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   */
  list(options?: QueryOptions) {
    // create query
    const url = `/EvaluationQuestionType/List`;
    let query = this.createQuery<EvaluationQuestionTypeDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    query = query.withOptions(options);

    return query;
  }

  /**
   */
  listTranslated(options?: QueryOptions) {
    // create query
    const url = `/EvaluationQuestionType/ListTranslated`;
    let query = this.createQuery<EvaluationQuestionTypeDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    query = query.withOptions(options);

    return query;
  }
}

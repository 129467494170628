/**
 * HRNext Performance WebApi
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import {
  CalibrationScoreResultDto,
  CommonFormRequestDto,
  EmployeeEvaluationViewModel,
  MandatoryTargetEvaluationResult,
  MandatoryTargetEvaluationResultDto,
  MandatoryTargetEvaluationResultRequestDto,
  SaveCalibrationScoreViewModel,
  TargetOrderViewModel,
  TeamEvaluationStatusViewModel,
  YearParameter,
} from './models';
import { LinqService, QueryOptions } from 'linquest';

import { IAjaxProvider } from 'jinqu';

/**
 * EvaluationApi - API class
 */
export class EvaluationApi extends LinqService {

  /**
   * Creates a new EvaluationApi class.
   *
   * @param ajaxProvider The Linquest Ajax Provider to inject. When not specified, default Fetch provider will be used.
   */
  constructor(ajaxProvider?: IAjaxProvider) {
    super("/performance-api", ajaxProvider);
  }

  /**
   * @param request 
   */
  getEmployeeEvaluationAsync(request?: CommonFormRequestDto, options?: QueryOptions) {
    // create query
    const url = `/Evaluation/GetEmployeeEvaluationAsync`;
    let query = this.createQuery<EmployeeEvaluationViewModel>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = request;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param comparisonAnalysiseSearchDto 
   */
  getMandatoryTargetEvaluationByAdmin(comparisonAnalysiseSearchDto?: MandatoryTargetEvaluationResultRequestDto, options?: QueryOptions) {
    // create query
    const url = `/Evaluation/GetMandatoryTargetEvaluationByAdmin`;
    let query = this.createQuery<MandatoryTargetEvaluationResultDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = comparisonAnalysiseSearchDto;
    query = query.withOptions(options);

    return query;
  }

  saveCalibrationScoreAsync(saveCalibrationScoreViewModel?: Array<SaveCalibrationScoreViewModel>, options?: QueryOptions) {
    // create query
    const url = `/Evaluation/SaveCalibrationScoreAsync`;
    let query = this.createQuery<CalibrationScoreResultDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = saveCalibrationScoreViewModel;
    query = query.withOptions(options);

    return query;
  }

  /**
   * @param formId 
   */
  getTargetOfCurrentUser(formId?: string, options?: QueryOptions) {
    // create query
    const url = `/Evaluation/GetTargetOfCurrentUser`;
    let query = this.createQuery<TargetOrderViewModel>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'formId', value: formId && formId.toString() });
    query = query.withOptions(options);

    return query;
  }

  /**
   * @param yearParams 
   */
  listTeamEvaluationOfCurrentUser(yearParams?: YearParameter, options?: QueryOptions) {
    // create query
    const url = `/Evaluation/ListTeamEvaluationOfCurrentUser`;
    let query = this.createQuery<TeamEvaluationStatusViewModel>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = yearParams;
    query = query.withOptions(options);

    return query;
  }

  /**
   * @param mandatoryTargetEvaluationResultDtos 
   */
  saveMandatoryTargetEvaluationResultAsync(mandatoryTargetEvaluationResultDtos?: Array<MandatoryTargetEvaluationResultDto>, options?: QueryOptions) {
    // create query
    const url = `/Evaluation/SaveMandatoryTargetEvaluationResultAsync`;
    let query = this.createQuery<MandatoryTargetEvaluationResult>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = mandatoryTargetEvaluationResultDtos;
    query = query.withOptions(options);

    return query.firstAsync();
  }



}

/**
 * HRNext Performance WebApi
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { IAjaxProvider } from 'jinqu';
import { LinqService, QueryOptions } from 'linquest';
import {
  PerformanceFormDetailResultDto,
  PerformanceFormDetailCompletionRequestDto,
  PerformanceFormDetailEvaluationRequestDto,
  PerformanceFormDetailSaveTagRequestDto,
  PerformanceFormDetailCalculateRequestDto,
} from './models';

/**
 * PerformanceFormDetailResultApi - API class
 */
export class PerformanceFormDetailResultApi extends LinqService {

  /**
   * Creates a new PerformanceFormDetailResultApi class.
   *
   * @param ajaxProvider The Linquest Ajax Provider to inject. When not specified, default Fetch provider will be used.
   */
  constructor(ajaxProvider?: IAjaxProvider) {
    super("/performance-api", ajaxProvider);
  }

  /**
   * @param dto 
   */
  addOrUpdateAsync(dto?: PerformanceFormDetailResultDto, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormDetailResult/AddOrUpdateAsync`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = dto;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param calculateRequest 
   */
  calculateTargetEvaluationScore(calculateRequest?: PerformanceFormDetailCalculateRequestDto, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormDetailResult/CalculateTargetEvaluationScore`;
    let query = this.createQuery<number>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = calculateRequest;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param completeRequest 
   */
  completeAsync(completeRequest?: PerformanceFormDetailCompletionRequestDto, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormDetailResult/CompleteAsync`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = completeRequest;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param id 
   */
  deleteAsync(id?: string, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormDetailResult/DeleteAsync`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param evaluateRequest 
   */
  evaluateAsync(evaluateRequest?: PerformanceFormDetailEvaluationRequestDto, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormDetailResult/EvaluateAsync`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = evaluateRequest;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param id 
   */
  getAsync(id?: string, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormDetailResult/GetAsync`;
    let query = this.createQuery<PerformanceFormDetailResultDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param id 
   */
  getTranslatedAsync(id?: string, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormDetailResult/GetTranslatedAsync`;
    let query = this.createQuery<PerformanceFormDetailResultDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   */
  list(options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormDetailResult/List`;
    let query = this.createQuery<PerformanceFormDetailResultDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    query = query.withOptions(options);

    return query;
  }

  /**
   */
  listTranslated(options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormDetailResult/ListTranslated`;
    let query = this.createQuery<PerformanceFormDetailResultDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    query = query.withOptions(options);

    return query;
  }

  /**
   * @param dto 
   */
  saveAsync(dto?: PerformanceFormDetailResultDto, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormDetailResult/SaveAsync`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = dto;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param resultList 
   */
  saveTagsAsync(resultList?: Array<PerformanceFormDetailSaveTagRequestDto>, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormDetailResult/SaveTagsAsync`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = resultList;
    query = query.withOptions(options);

    return query.firstAsync();
  }
}

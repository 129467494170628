import './timeline.scss'

import { Component, Vue } from 'vue-property-decorator'

@Component({
  template: require('./timeline.pug'),
  props: {
    timelineStartDate: {
      type: Date,
      default: function(): Date {
        const now = new Date()
        return new Date(now.getFullYear(), 0, 1)
      }
    },
    timelineEndDate: {
      type: Date,
      default: function(): Date {
        const now = new Date()
        return new Date(now.getFullYear(), 11, 31)
      }
    },
    mileStones: {
      type: Array,
      default: () => []
    },
    mileStoneSelectedId: {
      type: String,
      default: function(): string {
        return null
      }
    },
    showMonthNames: {
      type: Boolean,
      default: function(): boolean {
        return true
      }
    },

    showMileStonePopover: {
      type: Boolean,
      default: function(): boolean {
        return false
      }
    }
  }
})
export class TimelineComponent extends Vue {
  constructor() {
    super()
  }

  // Props
  private timelineStartDate: Date
  private timelineEndDate: Date
  private mileStones: any
  private mileStoneSelectedId: string
  //

  // UI Vars
  private timelineStartWeek: number
  private timelineEndWeek: number
  private timelineBarCssVars: any
  private mileStonesData = []
  //
  popoverContent = ''

  created(): void {
    this.setTimelineRange()
    this.setCssVars()
    this.$watch(
      'mileStones',
      function(newVal, oldVal) {
        this.mileStonesData = this.getMilestones(newVal)
      },
      { deep: true, immediate: true }
    )
  }

  setTimelineRange(): void {
    const monthNoStart = this.timelineStartDate.getMonth()
    const monthNoEnd = this.timelineEndDate.getMonth()
    this.timelineStartWeek = Math.ceil(this.timelineStartDate.getDate() / 7)
    this.timelineStartWeek = this.timelineStartWeek == 5 ? 4 : this.timelineStartWeek
    this.timelineStartWeek = this.timelineStartWeek + monthNoStart * 4

    this.timelineEndWeek = Math.ceil(this.timelineEndDate.getDate() / 7)
    this.timelineEndWeek = this.timelineEndWeek == 5 ? 4 : this.timelineEndWeek
    this.timelineEndWeek = this.timelineEndWeek + monthNoEnd * 4
  }

  setCssVars(): void {
    this.timelineBarCssVars = {
      '--timeline-bar-left': 'calc((100% / 48 *' + (this.timelineStartWeek - 1) + ') - 5px)',
      '--timeline-bar-width': 'calc(100% / 48 *' + (this.timelineEndWeek - this.timelineStartWeek + 1) + ')'
    }
  }

  getMilestones(mileStones: Array<any>): Array<any> {
    const arr = Array<any>()
    for (let i = 0; i < mileStones.length; i++) {
      if (mileStones[i].date) {
        const monthNo = mileStones[i].date.getMonth() + 1
        let weekNo = Math.ceil(mileStones[i].date.getDate() / 7)
        if (weekNo == 5) weekNo = 4
        arr.push({
          id: mileStones[i].id,
          month: monthNo,
          week: weekNo,
          name: mileStones[i].name,
          status: mileStones[i].status
        })
      }
    }
    return arr
  }

  getMilestone(monthIndex: number, weekIndex: number): any[] {
    // console.log(monthIndex + ' ' + weekIndex)
    return this.mileStonesData
      .asQueryable()
      .where(x => x.month == monthIndex + 1 && x.week == weekIndex + 1)
      .toArray()
  }

  milestoneSelected(milestone: any): void {
    this.popoverContent = milestone.name
    this.$emit('mileStoneSelected', milestone)
  }
}

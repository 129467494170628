/**
 * HRNext Performance WebApi
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { IAjaxProvider } from 'jinqu';
import { LinqService, QueryOptions } from 'linquest';
import {
  ConsumerJobDto,
} from './models';

/**
 * ConsumerJobApi - API class
 */
export class ConsumerJobApi extends LinqService {

  /**
   * Creates a new ConsumerJobApi class.
   *
   * @param ajaxProvider The Linquest Ajax Provider to inject. When not specified, default Fetch provider will be used.
   */
  constructor(ajaxProvider?: IAjaxProvider) {
    super("/performance-api", ajaxProvider);
  }

  /**
   * @param dto 
   */
  addOrUpdateAsync(dto?: ConsumerJobDto, options?: QueryOptions) {
    // create query
    const url = `/ConsumerJob/AddOrUpdateAsync`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = dto;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param id 
   */
  deleteAsync(id?: string, options?: QueryOptions) {
    // create query
    const url = `/ConsumerJob/DeleteAsync`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param id 
   */
  getAsync(id?: string, options?: QueryOptions) {
    // create query
    const url = `/ConsumerJob/GetAsync`;
    let query = this.createQuery<ConsumerJobDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param consumerJobType 
   * @param consumerJobStatus 
   */
  getJobProgressCount(consumerJobType?: 0 | 1 | 2, consumerJobStatus?: 0 | 1 | 2 | 3 | 4, options?: QueryOptions) {
    // create query
    const url = `/ConsumerJob/GetJobProgressCount`;
    let query = this.createQuery<number>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'consumerJobType', value: consumerJobType && consumerJobType.toString() });
    // set query parameters
    options.params.push({ key: 'consumerJobStatus', value: consumerJobStatus && consumerJobStatus.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param consumerJobType 
   * @param consumerJobStatus 
   */
  getJobTotalCount(consumerJobType?: 0 | 1 | 2, consumerJobStatus?: 0 | 1 | 2 | 3 | 4, options?: QueryOptions) {
    // create query
    const url = `/ConsumerJob/GetJobTotalCount`;
    let query = this.createQuery<number>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'consumerJobType', value: consumerJobType && consumerJobType.toString() });
    // set query parameters
    options.params.push({ key: 'consumerJobStatus', value: consumerJobStatus && consumerJobStatus.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param companyId 
   * @param consumerJobType 
   * @param consumerJobStatus 
   */
  getJobWithTypeAndStatus(companyId?: string, consumerJobType?: 0 | 1 | 2, consumerJobStatus?: 0 | 1 | 2 | 3 | 4, options?: QueryOptions) {
    // create query
    const url = `/ConsumerJob/GetJobWithTypeAndStatus`;
    let query = this.createQuery<ConsumerJobDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'companyId', value: companyId && companyId.toString() });
    // set query parameters
    options.params.push({ key: 'consumerJobType', value: consumerJobType && consumerJobType.toString() });
    // set query parameters
    options.params.push({ key: 'consumerJobStatus', value: consumerJobStatus && consumerJobStatus.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param id 
   */
  getTranslatedAsync(id?: string, options?: QueryOptions) {
    // create query
    const url = `/ConsumerJob/GetTranslatedAsync`;
    let query = this.createQuery<ConsumerJobDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param consumerJobTypes 
   */
  isAnyJobInProgress(consumerJobTypes?: 0 | 1 | 2, options?: QueryOptions) {
    // create query
    const url = `/ConsumerJob/IsAnyJobInProgress`;
    let query = this.createQuery<boolean>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'consumerJobTypes', value: consumerJobTypes && consumerJobTypes.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   */
  list(options?: QueryOptions) {
    // create query
    const url = `/ConsumerJob/List`;
    let query = this.createQuery<ConsumerJobDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    query = query.withOptions(options);

    return query;
  }

  /**
   */
  listTranslated(options?: QueryOptions) {
    // create query
    const url = `/ConsumerJob/ListTranslated`;
    let query = this.createQuery<ConsumerJobDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    query = query.withOptions(options);

    return query;
  }

  /**
   * @param dto 
   */
  saveAsync(dto?: ConsumerJobDto, options?: QueryOptions) {
    // create query
    const url = `/ConsumerJob/SaveAsync`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = dto;
    query = query.withOptions(options);

    return query.firstAsync();
  }
}
